<template>
  <section>
    <div class="name-and-bar-container" v-show="filesToUpload.length !== 0">
      <li class="item-li" v-for="file in filesUploadList" :key="file.id">
        <label class="checkbox-container">
          <input type="checkbox" />
          <span 
            class="checkmark check-before-uploading" 
            :style="setCustomClassFilesToUpload(2022)" 
            :class="`file-${2022}`"
          >
            {{'✔'}}
          </span>
        </label>

        <h4 class="file-text-name">{{ file.name }}</h4>
        <img src="@/assets/Trash.svg" class="trash-image" @click="removeFileFromFilesToUploadById(file)" />
      </li>
 
      <div class="progress-bar-container">
        <div class="progress-bar" v-show="showProgressBar">
            <div class="progress-info">
              <div :class="[showProgressBar === 100 ? 'bar-progress-complete' : 'bar-progress-processing', 'progress-load']" :style="{ width: percentageLoad + '%' }"></div>
            </div>
          </div>
        </div>
      </div>
        
    <div v-show="listUploadedFiles.length > 0">
      <!-- List of uploaded files -->
      <div class="uploaded-files">
        <div class="list-container">
          <h3 class="title-tick-untick">The list of your uploaded files appears here. Tick/Untick to use in your data
            analysis, or delete.</h3>
          <!-- List of uploaded files -->
          <ul v-if="listUploadedFiles.length > 0">
            <!-- <h3>Uploaded files</h3> -->
            <li class="item-li" v-for="({ year, enabled, filename }) in listUploadedFiles" :key="year">
              <label class="checkbox-container">
                <input type="checkbox" :class="`input-${year}`" />
                <span 
                  class="checkmark" 
                  :style="setCustomClass(year)" 
                  :class="`file-${year}`"
                  @click="checkOptions(year)"
                >
                  {{ enabled ? '✔' : '' }}
                </span>
              </label>
              <h4 class="file-text-name">{{ filename }}</h4>
              <img src="@/assets/Trash.svg" class="trash-image" @click="openModalToDelete(year)" />
            </li>
          </ul>
        </div>
      </div>
      <div class="container-pop-up-card">
        <PopUpCardModal v-for="(modal, index) in modals" :key="index" ref="modalAndPopUpCardRef" :titleCard="modal.title"
          :textMsg="modal.text" :isDelete="modal.isDelete" :deleteFileById="deleteFile"
          :isOk="modal.isOk" />
      </div>
    </div>

  </section>
</template>

<script>

import { getLineChartColorScheme } from '../../data/colour-scheme.js'

import PopUpCardModal from '../../components/shared/PopUpCardModal.vue';

import { deleteFileFromList, checkFileYear } from '../../services/files';

export default {
  name: 'UploadedFiles',
  components: {
    PopUpCardModal
  },

  props: {
    listUploadedFiles: {
      type: Array,
      default: () => [],
    },
    company: {
      type: [],
      default: null,
    },
    project: {
      type: Object
    },
    percentageLoad: {
      type: Number, 
    },
    showProgressBar: {
      type: Boolean,
    },
    filesToUpload: {
      type: Array,
      default: () => [],
      validator: (files) => {
        // Validate each file object and set 'enabled' to false if it's missing
        files.forEach((file) => {
          if (typeof file.enabled === 'undefined') {
            file.enabled = true;
          }
        });
        return true; // Always return true to accept the default value
      },
    },
  },

  data() {
    return {
      idFile: null,
      loading: false,
      filesUploadList: [],
      modals: [
        {
          title: 'Delete a file',
          text: 'Once you confirm delete here, the file will be permanently deleted from our platform.',
          isDelete: true,
        },
        {
          title: 'File unticked',
          text: 'the file you unticked will not be used in your data analysis.',
          isOk: true,
        },
        {
          title: 'Action cancelled.',
          text: 'Your file has not been deleted.',
          isOk: true,
        },
        {
          title: 'File deleted',
          text: 'Your file has been deleted.',
          isOk: true,
        }, 
      ],
    }
  },

  methods: {
    setCustomClassFilesToUpload(id) {
      const dynamicColor = getLineChartColorScheme(id); // Calculate dynamic color based on the id
      // Return a string representing the CSS classes
      return `border: 2px solid ${dynamicColor}; color: ${dynamicColor}`;
    },
    removeFileFromFilesToUploadById(file) {
      this.$emit("remove-file-from-dropzone", file.id);
      const index = this.filesToUpload.findIndex(f => f.id === file.id);
      if (index !== -1) {
        this.filesToUpload.splice(index, 1);
      }
    },
    //  This method is used in another component (do not delete)
    removeFileFromFilesToUpload(file) {
      const index = this.filesToUpload.findIndex(f => f.name === file.name);
      if (index !== -1) {
        this.filesToUpload.splice(index, 1);
      }
    },
    showModal(modalIndex) {
      const modalRef = this.$refs.modalAndPopUpCardRef[modalIndex];
      modalRef.showModal();
    },
    setCustomClass(index) {
      const dynamicColor = getLineChartColorScheme(index);
      const dynamicStyleBorder = `border: 2px solid ${dynamicColor}; color: ${dynamicColor}`;

      return dynamicStyleBorder;
    },

    async checkOptions(file_year) {
      if (this.loading) return;

      let fileSearch = this.listUploadedFiles.find((file) => {
        return file.year === file_year;
      });

      fileSearch.enabled = !fileSearch.enabled;
      await this.checkYear(this.project.value, fileSearch.year, fileSearch.enabled);
      if (!fileSearch.enabled) {
        // this.$refs.modalUploadedFiles.show();
        this.showModal(1);
      }
    },

    openModalToDelete(year) {
      this.idFile = year;
      this.showModal(0);
    },
    async checkYear(project, year, enabled) {
      try {
        if (this.loading) {
          return;
        }
        this.loading = true;
        await checkFileYear({
          idProject: project,
          year,
          enabled
        });
        // console.log("checkYear", response);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async deleteFile() {
      try {
        if (this.loading) {
          return;
        }
        this.$refs.modalAndPopUpCardRef[0].closePopUp();

        this.loading = true;
        const year = this.idFile;
        const company = this.company.value;
        const project = this.project.value;

        const resp = await deleteFileFromList({ year, company, project });

        // If the file is successfully deleted, it will be removed from the listUploadedFiles
        if (!resp.error) {
          this.showModal(3);
          let position = this.listUploadedFiles.findIndex(file => file.year === year);
          if (position !== -1) {
            this.listUploadedFiles.splice(position, 1);
          }
        }
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    filesToUpload(newValue) {
      this.filesUploadList = newValue;
    },
  }
};
</script>

<style src="../../assets/styles/components/project/UploadedFiles.css"></style>
