<template>
  <div class="projectContainerBase">
    <div class="projectContainerTitle">
      <h3 class="projectTitle">Your project(s)</h3>
      <div class="projectArchivedContainer">
        <button
          class="buttonArchived"
          :class="{ activeArchived: !showArchivedProjects }"
          @click="unarchivedProjects"
          :disabled="loadingProjects || showArchivedCompanies"
        >
          Current
        </button>
        <span class="projectSeparator"></span>
        <button
          class="buttonArchived"
          :class="{ activeArchived: showArchivedProjects }"
          @click="archivedProjects"
          :disabled="loadingProjects"
        >
          Archive
        </button>
      </div>
    </div>

    <div class="comp-org-container">
      <b-modal ref="modalProjContainer" size="sm" centered @hide="modalHidden" hide-footer>
        <PopUpCard 
        v-if="isPopupActive"
        :isPopupActive="isPopupActive"
        :titleCard="titleCard"
        :textMsg="textMsg"
        :closePopUp="closePopUp"
        :isOk="true"
        />
      </b-modal>
    </div>
    <br />
      <article v-if="(companySelected.value !== null && !companySelected.value !== '') && projectList.length == 0" class="addNewOption">

        <input ref="inputTextEditObjective" type="text" class="objective-text input-text-company-edit"
          v-model="userInputSubComp" 
          :placeholder="projectPlaceholder" 
          @keyup="getText(userInputSubComp, $event)"
          @focus="projectPlaceholder = ''"
          @blur="projectPlaceholder = originalPlaceholder" 
          :readonly="showArchivedCompanies"
        />
        <input value="Save" type="button" class="btn-save" v-if="onKeyupEvent && showArchivedCompanies === false" @click="createProjects(userInputSubComp)" />
      </article>

      <!-- if there is only one project created, this appearance of the dropdown should be shown -->
      <article v-if="projectList.length == 1" class="container-inputs-proj" v-clickaway="closeInput">
        <div class="first-input-btn-add">
          <input type="text" class="objective-text" v-model="projectList[0].label" disabled
            @keyup="getText(userInputSubComp, $event)" />
          <input
             v-if="showArchivedProjects == false && 
             showArchivedCompanies === false &&
             currentRole !== 'viewer' &&
             currentRole !== 'editor'
            " 
             class="add-objective-button" 
             type="button" 
             @click="onHandleWriteProject" 
             :class="isCreatingProject ? 'rotate-45' : ''" 
            />
        </div>

        <!-- isCreatingProject conditions whether the input for writing the name of the project to be created is shown or not -->
        <div class="container-inputTextEditObjective">
          <input v-if="isCreatingProject" ref="inputTextEditObjective" type="text" :readonly="showArchivedCompanies"
            class="objective-text" v-model="userInputSubComp" :placeholder="projectPlaceholder"
            @keyup="getText(userInputSubComp, $event)" 
            />
          <input value="Save" type="button" class="btn-save" v-if="onKeyupEvent && showArchivedCompanies === false"
            @click="createProjects(userInputSubComp)" />
        </div>
      </article>

      <!-- if there is more than one project created, this appearance of the dropdown should be shown -->
      <!-- <article v-if="modifyProjects == true && projectList.length > 1" class="addNewOption container-organisation-inputs"> -->
      <article v-if="projectList.length > 1" class="addNewOption container-organisation-inputs">
        <div class="select-and-button-add">
          <v-select placeholder="All Projects" v-model="selectedProject" :options="projectList" :clearable="false"
            :loading="loadingProjects" @input="updateSelectedProject">
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes">
                <img src="../../assets/Dropdown.svg" />
              </span>
            </template>
          </v-select>
  
          <!-- add button (circle button (+) ) -->
          <!-- It shouldn't be necessary, but just in case (currentRole !== 'editor')-->
          <button
             @click="onHandleWriteProject"
             v-if="
              showArchivedProjects == false &&
              showArchivedCompanies === false &&
              currentRole !== 'editor'
             "
             type="button" 
             class="addOption" 
             :class="isCreatingProject ? 'rotate-45' : ''">
          </button>
        </div>
  
        <div v-if="isCreatingProject" v-clickaway="closeInput">
          <input 
            ref="inputTextEditObjective"
            type="text" 
            class="objective-text"
            v-model="userInputSubComp"
            :placeholder="projectPlaceholder" 
            @keyup="getText(userInputSubComp, $event)"  
            :readonly="showArchivedCompanies"
            />
            <!-- @focus="projectPlaceholder = ''" -->
            <!-- @blur="projectPlaceholder = originalPlaceholder" -->
          <input value="Save" type="button" class="btn-save" v-if="onKeyupEvent"
            @click="createProjects(userInputSubComp)" />
        </div>
  
      </article>
    <article
      class="objective-vfor-wrapper-subtitle-2"
      v-if="
        showArchivedProjects == true &&
        companySelected.archived == false &&
        (projectList.length == 0 || projectList[0].value == null)
      "
    >
      <h3>Currently there's no archived project</h3>
    </article>
    <article
      class="objective-vfor-wrapper-subtitle-2"
      v-if="
        companySelected.archived == true &&
        (projectList.length == 0 || projectList[0].value == null)
      "
    >
      <h3>This archived organisation has no projects {{ companySelected.archived ? 'archived' : '' }}</h3>
    </article>
    <article
      class="addNewOption"
      v-if="companySelected.value == null && loadingProjects == false"
    >
      <input 
        ref="inputTextEditObjective"
        type="text" 
        class="objective-text"
        v-model="userInputSubComp"
        :placeholder="projectPlaceholder"
        :readonly="companySelected.value == null && loadingProjects == false"
        @keyup="getText(userInputSubComp, $event)"  
      />
    </article>

    <b-modal ref="modalSuccessAddProject" size="sm" centered hide-footer>
      <div class="label-container">
        <h3 class="label-text">
          {{ modalMessage }}
        </h3>
        <button
          id="finishEditProject"
          class="ok-button"
          @click="closeModalProject"
        >
          Ok
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { directive as clickaway } from 'vue-clickaway';
import { createProject } from "../../services/project";
import PopUpCard from "../shared/PopUpCard.vue";
import { reinitializeSocketWithToken } from "../../services/socket_background.js";

export default {
  name: "ProjectSection",
  directives: {
    clickaway,
  },
  components: {
    PopUpCard,
  },
  props: {
    selected: {
      type: Object,
    },
    companySelected: {
      type: Object,
    },
    projectCreationInputFocus: {
      type: Boolean,
    },
    projectList: {
      type: Array,
      required: true,
    },
    loadingProjects: {
      type: Boolean,
    },
    showArchivedProjects: {
      type: Boolean,
    },
    showArchivedCompanies: {
      type: Boolean,
    },
    currentRole: {
      type: String,
    }
  },
  data() {
    return {
      modalMessage: "",
      firstProject: false,
      modifyProjects: true,
      projectPlaceholder: "e.g. INS project",
      originalPlaceholder: "e.g. INS project",
      selectedProject: {},
      isPopupActive: false,
      titleCard: "Project",
      textMsg: "Your project has been created. Thank you!",
      isCreatingProject: false,
      onKeyupEvent: false,
      userInputSubComp: null,
      optionName: "",
    };
  },
  async mounted() {
    if (this.companySelected.value) {
      if (this.$cookies.isKey('lastSelectedOptionProject')) {
        
        const lastSelectedOptionProject = this.$cookies.get('lastSelectedOptionProject');
        this.selectedProject = lastSelectedOptionProject;
      }
    }
  },
  methods: {
    loadProjects(archived = false, selectLastProj = false) {
      this.$emit("load-projects-list", archived, selectLastProj);
    },
    closeInput() {
      this.isCreatingProject = false;
    },
    modalHidden() {
      document.removeEventListener('keydown', this.handleKeydown);
    },
    handleKeydown(event) {
      if (event.key === 'Enter') {
        this.$refs.modalProjContainer.hide();
        this.isPopupActive = false;
      }
    },
    async createProjects(project) {
      try {
        if (this.loadingProjects) {
          return;
        }
        const res_data = await createProject(this.companySelected.value, {
          name: project,
          order: this.projectList.length + 1,
        });

        this.onKeyupEvent = false;
        if (res_data.error === false) {
          this.isPopupActive = true;
          this.titleCard = "Project created";
          this.textMsg = "Your project has been created. Thank you!";
          this.loadProjects(false, true);
          // this.modifyProjects = false;
          this.userInputSubComp = null;
          this.isCreatingProject = false; 
          reinitializeSocketWithToken(localStorage.getItem("token"));
          this.$refs.modalProjContainer.show();
        } else {
          if (res_data?.message) {
             this.textMsg = res_data.message;
             this.isPopupActive = true;
             this.$refs.modalProjContainer.show();
          } else {
             this.textMsg = "An error occurred while creating the project.";
          }
        }
        // this.$refs.modalSuccessAddProject.show();
      } catch (error) {
         this.textMsg = "An error occurred while making the request.";
        // this.$refs.modalSuccessAddProject.show();
      } finally {
        // this.loadingProjects = false;
      }
    },

    onHandleWriteProject() {
      this.isCreatingProject = !this.isCreatingProject;
      if(this.isCreatingProject) {
        this.$nextTick(() => {
        this.$refs.inputTextEditObjective.focus();
      });
      }
    },

    getText(event, keyBoard) {
      if (this.optionName !== event) {
        this.onKeyupEvent = true;
      } else {
        this.onKeyupEvent = false;
      }
      if (keyBoard.key === "Enter") {
        if(this.userInputSubComp) {
          this.createProjects(
            this.userInputSubComp,
          );
        }
        this.onKeyupEvent = false;
        // this.isCreatingCompany = false;
        this.userInputSubComp = null;
      } else if (keyBoard.key === "Escape") {
        this.cancelEdit();
      }
    },

    cancelEdit() {
      this.edit = false;
      this.onKeyupEvent = false;
      this.isCreatingProject = false;
    },

    closeModalProject() {
      this.$refs.modalSuccessAddProject.hide();
    },
    unarchivedProjects() {
      if (this.showArchivedProjects == false) {
        return;
      }
      this.$emit('load-projects-list', false);
      this.$emit('toggle-archived-projects', false);
      this.updateSelectedProject({ label: "", value: null, archived: null });
    },
    archivedProjects() {
      if (this.showArchivedProjects == true) {
        return;
      }
      this.$emit('load-projects-list', true);
      this.$emit('toggle-archived-projects', true);
      this.updateSelectedProject({ label: "", value: null, archived: null });
    },
    updateSelectedProject(newValue) {
      if(newValue && newValue.value){
        this.$cookies.set('lastSelectedOptionProject', newValue)
        // this.selectedProject = newValue;
        this.$emit("selected-project-changed", newValue);
      }
    },
    closePopUp() {
      this.isPopupActive = false;
      this.$refs.modalProjContainer.hide();
    },
  },
  watch: {
    selected() {
      this.selectedProject = this.selected;
    },
    companySelected() {
      this.firstProject = false;
      this.projectPlaceholder = this.originalPlaceholder;

      this.loadProjects(this.showArchivedProjects);
    },
    isPopupActive(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          document.addEventListener('keydown', this.handleKeydown);
        });
      }
    },
    projectCreationInputFocus(value) {
      if(value && this.projectList.length === 0) {
        this.$refs.inputTextEditObjective.focus();
      }
    }
  },
};
</script>
<style src="@/assets/styles/components/editProject/ProjectSection.css"></style>
