<template>
  <section class="data-link-container">
    <div class="project-data-area">
      <div class="file-upload-area">
        <div class="file-upload-area-container">
          <h3 class="file-upload-area-subtitle">
            Drag & drop your csv file(s) and create your dashboards in the following step.
          </h3>
          <vue-dropzone ref="imgDropZone" id="customdropzone" :options="dropzoneOptions" :duplicateCheck="true"
            @vdropzone-file-added="handleFileDrop" @vdropzone-success="fileAdded"
            @vdropzone-total-upload-progress="onCargaArchivo">
          </vue-dropzone>

          <uploaded-files ref="uploadedFiles" :listUploadedFiles="listUploadedFiles" :company="company"
            :project="selectedProject" :percentageLoad="percentageLoad" :showProgressBar="showProgressBar"
            :termsAndConditionCheck="termsAndConditionCheck" @remove-file-from-dropzone="removeFileFromDropzone" />
        </div>

        <div class="terms-checkbox-area" v-if="isValidFile || termsAndConditionCheck || listUploadedFiles.length !== 0">
          <input type="checkbox" id="terms-checkbox" @click="checkTermsAndCondition" v-model="termsAndConditionCheck" />

          <label class="terms-checkbox" for="terms-checkbox">
            <div class="terms-checkbox-div" for="terms-checkbox">
              <span class="terms-checkbox-v" for="terms-checkbox">✔</span>
            </div>
          </label>
          <div class="upload-agreement-container">
            <h3 class="upload-sub-title placeholders-gray">
              Please tick the box to agree with our <br />
              <a href="#">Data Processing Terms & Conditions</a>
            </h3>
          </div>
        </div>
        <div v-else>
          <h3 class="upload-sub-title placeholders-gray">
            <a href="#">Data Processing Terms & Conditions</a>
          </h3>
        </div>
      </div>

      <div class="or-divider">
        <span>or</span>
      </div>

      <div class="upload-logo-area">
        <h3 class="upload-logo-area-subtitle">
          Connect to your database on:
        </h3>
        <div class="database-imgs-container">
          <div class="image-container">
            <img src="../assets/SalesforceLogo.svg" />
            <span class="badge">{{ badgeText }}</span>
          </div>
          <div class="image-container">
            <img src="../assets/AwsLogo.svg" />
            <span class="badge">{{ badgeText }}</span>
          </div>
          <div class="image-container">
            <img src="../assets/editProject/surMonkeyLogo.svg" />
            <span class="badge">{{ badgeText }}</span>
          </div>
        </div>
      </div>

      <div class="container-pop-up-card">
        <PopUpCardModal v-for="(modal, index) in modals" :key="index" ref="modalAndPopUpCardRef" :titleCard="modal.title"
          :textMsg="modal.text" :isYearRepeated="modal.isYearRepeated" :isOk="modal.isOk" :csvOverwrite="csvOverwrite" />
        <PopUpCardModal ref="modalErrorFiles" titleCard="File format error" :textMsg="errorFileMsg" :isOk="true"
          :isYearRepeated="false" :csvOverwrite="closePopUpErrorFile" />
      </div>

    </div>
    <div class="button-upload-container">
      <button :disabled="isUploadButtonDisabled" class='text-upload' @click="$refs.imgDropZone.processQueue();">
        <span>Upload</span>
      </button>
    </div>
  </section>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import UploadedFiles from '../components/project/UploadedFiles.vue';
import PopUpCardModal from "./shared/PopUpCardModal.vue";
import { fileOverwriteManager, useInsertDataOfExcel, getCurrentDashboardsYears } from "../services/files";
import config from "../config.js"

export default {
  name: "UploadModule",
  components: {
    vueDropzone: vue2Dropzone,
    UploadedFiles,
    PopUpCardModal,
  },
  props: {
    filesNames: {
      type: String
    },
    company: {
      type: [],
      default: null,
    },
    selectedProject: {
      type: Object
    },
    listUploadedFiles: {
      type: Array,
      default: () => [],
    },
    showArchivedCompanies: {
      type: Boolean,
    },
    showArchivedProjects: {
      type: Boolean,
    }
  },
  data() {
    return {
      dropzoneTotalFilesize: 0,
      dropzoneUploadedFilesize: 0,
      dropzoneCurrentUpload: 0,
      showProgressBar: false,
      percentageLoad: 0,
      dropzoneOptions: {
        url: `${config.API_URL}/user/file`,
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        sending: (file) => this.onFileAdded(file),
        addRemoveLinks: false,
        acceptedFiles: ".csv",
        dictDefaultMessage: `
        <p class="drag-drop-text">Drag & Drop your <b>CSV</b> file(s) here.</p>
        <p class="drag-drop-text-subtitle"><br><b>Only CSV files</b> are allowed for now.</p>
        `,
        autoProcessQueue: false,
      },
      selected: [],
      termsAndConditionCheck: null,
      actualYearUpload: "0",
      currentYearsArr: [],
      isPopupErrorFilesActive: false,
      titleCard: "Data uploaded",
      textMsg: "Thank you for uploading your data. You are now ready to create your dashboards. Select a metric from the drop-down menu below and shape your dashboards as you wish.",
      isYearRepeated: false,
      formData: null,
      filesLoadedCount: 0,
      errorFileMsg: "",
      deletedFileName: '',
      modals: [
        {
          title: 'Data uploaded',
          text: 'Your data has been uploaded. In the next step, you can create your first dashoard and customise it as you wish.',
          isOk: true,
        },
        {
          title: 'Repeated year',
          text: 'It seems that you\'re trying to upload a CSV with data of the same time period. If you choose "OK" below the CSV you\'re uploading will overwrite the CSV you\'ve already uploaded. If you don\'t want that, please choose "Cancel"',
          isYearRepeated: true,
        },
        {
          title: 'Updated file',
          // text: `The CSV file ${this.deletedFileName} has been successfully updated`,
          text: '',
          isOk: true,
        },
        {
          title: 'File(s) saved',
          text: 'Your file(s) has (have) been saved. Please tick the box below to agree with our \'Data Processing Terms & Conditions\' and upload the file(s) to our decentralized database.',
          isOk: true,
        },
      ],
      isValidFile: false,
      filesToUpload: [],  // Array para almacenar los archivos a subir
      loading: false,
      badgeText: 'Coming soon!',
    };
  },
  mounted() {
    const cookieValue = this.$cookies.get('isTermsAccepted');
    this.termsAndConditionCheck = cookieValue === 'true';
  },
  computed: {
    isUploadButtonDisabled() {
      return (
        this.filesToUpload.length === 0 ||
        this.loading ||
        !this.selectedProject.value ||
        !this.isValidFile ||
        !this.termsAndConditionCheck ||
        (this.showArchivedCompanies && this.showArchivedProjects)
      );
    },
  },
  watch: {
    selectedProject: {
      immediate: true,
      async handler() {
        this.currentYearsArr = []
        this.actualYearUpload = "0"
        if (this.selectedProject.value) {
          const data = await this.getCurrentDashboardsYears(this.selectedProject.value);
          if (data.data.data && data.data.data.arrayYears?.length !== 0) {
            data.data.data.arrayYears.map(({ year }) => {
              this.currentYearsArr.push(year)
            });
          }
        }
      }
    },
    company: {
      immediate: true,
      handler() {
        this.currentYearsArr = []
        this.actualYearUpload = "0"
      }
    },
  },
  methods: {
    removeFileFromDropzone(fileId) {
      const dropzone = this.$refs.imgDropZone; // Obtén una referencia al componente Dropzone

      const fileToRemove = dropzone.getAcceptedFiles().find((file) => file.upload.uuid === fileId);
      if (fileToRemove) {
        dropzone.removeFile(fileToRemove); // Elimina el archivo del Dropzone
      }
    },
    onCargaArchivo(progress) {
      if (progress === 100 || progress === 0) {
        this.percentageLoad = 0;
        progress = 0;
        this.showProgressBar = false;
      } else {
        this.percentageLoad = progress;
        this.showProgressBar = true;
      }
    },
    handleFileDrop(file) {
      if (!file.name.endsWith('.csv')) {
        this.isValidFile = false;
        this.$refs.imgDropZone.removeFile(file);
        this.errorFileMsg = `Please upload a CSV file. We'll enable the addition of other file formats soon.`;
        this.isPopupErrorFilesActive = true;
        this.$refs.modalErrorFiles.showModal();
        return;
      }

      const newFile = {
        id: file.upload.uuid, // A unique identifier for the file
        name: file.name, // The file name
        percentageLoad: 0,
      };
      this.$refs.uploadedFiles.filesToUpload.push(newFile);
      this.filesToUpload = this.$refs.uploadedFiles.filesToUpload;
      // The file is valid, you can perform the necessary actions
      this.isValidFile = true;
      if (!this.termsAndConditionCheck) {
        this.showModal(3);
      }
    },
    showModal(modalIndex) {
      const modalRef = this.$refs.modalAndPopUpCardRef[modalIndex];
      modalRef.showModal();
    },
    async onFileAdded(file) {
      this.formData = new FormData();
      this.formData.append('file', file);
      this.formData.append('idCompany', this.company.value);
      this.formData.append('idProcess', this.selectedProject.value);
      // Here you can add any additional data to the formData object as needed
      this.loading = true;
      const response = await useInsertDataOfExcel(this.formData);

      if (response.error === false) {
        this.isYearRepeated = false;
        this.filesLoaded();

        if (this.listUploadedFiles.length > 0 || this.filesLoadedCount === 0) {
          this.filesLoadedCount++;
          this.showModal(0);
        }
        // remove file from filesToUpload array
        this.$refs.uploadedFiles.removeFileFromFilesToUpload(file);
        this.loading = false;
        return;
      }
      // This line is necessary to display in the popup the file that has just been updated
      this.formData.append('year', response.duplicateYear);
      // remove file from filesToUpload array
      this.$refs.uploadedFiles.removeFileFromFilesToUpload(file);
      this.loading = false;
      this.showModal(1);
    },
    closePopUpErrorFile() {
      this.$refs.modalErrorFiles.closePopUp();
      this.isPopupErrorFilesActive = false;
      this.errorFileMsg = "";
    },

    filesLoaded() {
      this.$emit('consultHeaders');
    },

    async getCurrentDashboardsYears(idProject) {
      return await getCurrentDashboardsYears(idProject);
    },
    fileAdded(file, response) {
      try {
        this.$refs.imgDropZone.removeFile(file);
        const dateRange = response.files.file.split(",")[0];
        const actualYear = dateRange.split("/").pop();
        this.actualYearUpload = actualYear;
        let filenamesArr = [];
        filenamesArr.push(file.upload.filename);
        // clean the dropzone after adding the files
        this.$nextTick(() => {
          this.$refs.imgDropZone.removeAllFiles();
        });
      } catch (error) {
        this.actualYearUpload = "0";
      }
    },
    checkTermsAndCondition() {
      this.termsAndConditionCheck = !this.termsAndConditionCheck;
      this.$cookies.set('isTermsAccepted', this.termsAndConditionCheck);
    },
    fileUploadStatusChange() {
      this.$emit("upload-status-change");
      this.$emit("fileUploadStatusChange");
    },
    async csvOverwrite() {
      // close the modal for duplicate year
      this.$refs.modalAndPopUpCardRef[1].closePopUp();
      const data = await fileOverwriteManager(this.formData);
      if (data.status === 200) {
        const msg = `The CSV file ${data.deletedFileName} has been successfully updated`;
        this.modals[2].text = msg;

        this.filesLoaded();
        // open the modal for updated file.
        this.showModal(2);
      }
    }
  },
};
</script>

<style src="../assets/styles/components/UploadModule.css"></style>
 