<template>
  <div class="project-title-input">
    <div v-if="objectiveSelected == false || projectSelected.value === null">
      <input class="selectedTitle" value="Your project's name" disabled />
    </div>
    <div
      @mouseover="saveButtonActive = true"
      @mouseleave="saveButtonActive = false"
      v-if="objectiveSelected == true"
      class="inputContent"
    >
    <h2 data-testid="project-name" class="name-organisation" v-if="!edit">{{projectSelected.label}}</h2>
    <div class="input-content-container">
      <input
        v-if="edit"
        @click="editF"
        type="text"
        @keyup="getText(userInputSubComp, $event)"
        @blur="removeFocus"
        ref="inputTextEditObjective"
        v-model="userInputSubComp"
        class="selectedTitle"
        :placeholder="placeholderText"
        :disabled="edit == false"
        data-testid="input-edit"
      />
      <section
        v-if="edit == false && projectSelected.value"
        class="position-relative"
      >
        <button @click="toggleActions" class="dots-vertical">
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div v-if="showActions == true" class="dots-actions">
          <div class="list-actions">
            <div
              class="actions-top"
            >
              <p>List of actions</p>
              <span @click="closeActions" class="cursor-pointer">x</span>
            </div>
            <hr class="line-gray" />
          </div>
          <p v-if="projectSelected.archived" @click="archiveProject(false)">
            Unarchive project
          </p>
          <template v-else>
            <p @click="renameAction">Rename project</p>
            <p v-if="currentRole !== 'editor'" @click="archiveProject(true)">
              Archive project
            </p>
          </template>
          <p v-if="currentRole !== 'editor'" @click="openModalToDelete">Delete project</p>
        </div>
      </section>
    </div>
      <div v-if="edit == true">
        <div class="tapOptions">
          <p @click="cancelEdit">Cancel</p>
          <p data-testid="button-save" @click="updateObjectives(userInputSubComp)">Save</p>
        </div>
      </div>
      <section
        v-if="popup == true && projectSelected.archived == false"
        class="popup-archive-project"
      >
        <div class="popup-content">
          <div class="popup-info">
            <div class="popup-top">
              <h1>Archive this project</h1>
              <span @click="closePop" class="closeBtn">X</span>
            </div>
            <p>Click on "archive" on this pop up to archive</p>
            <p>this project. Once archived it will appear under</p>
            <p>"Archive" on the project(s) drop down above.</p>
          </div>
          <div class="popup-options">
            <label
              ><input type="checkbox" name="show" id="showAgain" /> Don't show
              me again</label
            >
            <p @click="archiveProject()">Archive</p>
          </div>
        </div>
      </section>
    </div>
    <PopUpCardModal 
      ref="modalInfoMessage" 
      :isPopupActive="true"
      :closePopUp="closePopUp"
      :titleCard="titleCard"
      :textMsg="modalMessage"
      :isOk="isOkPopUp"
      :isDelete="isDeletePopUp"
      :deleteFileById="deleteProjectById"
    />
  </div>
</template>

<script>
import { deleteProjectById, updateProject } from "../../services/project";
import PopUpCardModal from "../shared/PopUpCardModal.vue";

export default {
  props: {
    projectSelected: {
      type: Object,
      default: () => ({ "label": null, "value": null, "archived": false, "role": null }),
    },
    currentRole: {
      type: String,
    }
  },
  components: {
    PopUpCardModal,
  },
  data() {
    return {
      userInputSubComp: null,
      originaluserInputSubComp: null,
      optionName: "",
      onKeyupEvent: false,
      edit: false,
      saveButtonActive: false,
      objectiveSelected: false,
      popup: false,
      showActions: false,
      placeholderText: "e.g. INS project",
      loading: false,
      titleCard: "Project",
      modalMessage: "",
      isOkPopUp: true,
      isDeletePopUp: false,
      errorReqOcurred: 'An error occurred while making the request.',
    };
  },
  methods: {
    async updateObjectives(userInputSubComp) {
      this.$emit('updateObjectives', userInputSubComp);
      try {
        if (this.loading) {
          return;
        }
        this.loading = true;
        const res_data = await updateProject(this.projectSelected.value, {
          name: userInputSubComp,
        });
        if (res_data.error === false) {
          this.onKeyupEvent = false;
          this.edit = false;
          this.originaluserInputSubComp = this.userInputSubComp;
          this.$emit('project-updated', userInputSubComp);
          this.$emit('load-projects-list');
          this.optionName = "";
          this.titleCard = "Project name"
          this.modalMessage = "Project name successfully updated";
        } else {
          if (res_data?.message) {
            this.modalMessage = res_data.message;
          } else {
            this.modalMessage = "An error occurred while updating the project.";
          }
        }
        this.$refs.modalInfoMessage.showModal();
      } catch (error) {
        this.modalMessage = this.errorReqOcurred;
        this.$refs.modalInfoMessage.showModal();
      } finally {
        this.loading = false;
      }
    },
    updateProjectList(archived, selectLastProj) {
      this.$emit("load-projects-list", archived, selectLastProj);
    },
    closePopUp() {
      if(this.$refs?.modalInfoMessage) {
        this.$refs.modalInfoMessage.hide();
      }
    },
    editF() {
      this.edit = true;
      this.originaluserInputSubComp = this.projectSelected.label;
      if (this.optionName === "") {
        this.optionName = this.originaluserInputSubComp;
      }
    },
    setText() {
      if(!this.projectSelected.value) return;
      this.userInputSubComp = this.projectSelected.label;
      if (this.projectSelected.value == null || this.projectSelected.value === "") {
        this.objectiveSelected = false;
      } else {
        this.objectiveSelected = true;
      }
    },
    getText(event, keyBoard) {
      if (this.optionName !== event) {
        this.onKeyupEvent = true;
      } else {
        this.onKeyupEvent = false;
      }
      if (keyBoard.key === "Enter") {
        this.updateObjectives(
          this.userInputSubComp
        );
        this.onKeyupEvent = false;
      } else if (keyBoard.key === "Escape") {
        this.cancelEdit();
      }
    },
    addFocus() {
      setTimeout(() => {
        if (this.$refs.inputTextEditObjective) {
          this.$refs.inputTextEditObjective.focus();
          this.editF();
        }
      }, 10);
    },
    removeFocus() {
      if (this.saveButtonActive === false) {
        this.cancelEdit();
      }
    },
    cancelEdit() {
      this.edit = false;
      this.onKeyupEvent = false;
      this.userInputSubComp = this.originaluserInputSubComp;
    },
    showCustomModal(title, contentMsg) {
      this.titleCard = title;
      this.modalMessage = contentMsg;
      this.isOkPopUp = true;
      this.isDeletePopUp = false;
      this.$refs.modalInfoMessage.showModal();
    },
    async archiveProject(isArchived = true) {
      try {
        if (this.loading) {
          return;
        }
        this.loading = true;
        const res_data = await updateProject(this.projectSelected.value, {
          archived: isArchived,
        });
        if (res_data.error === false) {
          this.$cookies.remove('lastSelectedOptionProject');
          this.updateProjectList(false, true);
          this.$emit('toggle-archived-projects', false)
          this.closeActions();
          this.closePop();
          this.edit = false;
          this.onKeyupEvent = false;
          const archived = isArchived ? 'archived' : 'unarchived';
          // const capitalizedIsArchived = archived.charAt(0).toUpperCase() + archived.slice(1);
          const titleCard = `Project ${archived}`;
          let modalMessage = '';

          if(archived) {
            modalMessage = `Your project, along with all of its dashboards, has been ${archived}. You can switch between Current and Archived projects from the two options above.`;
          } else {
            modalMessage = `The Organisation, along with all of its projects, have been taken out of the archive state. You can switch between Current and Archived organisations from the two options above.`;
          }
          this.showCustomModal(titleCard, modalMessage);
        } else {
          this.modalMessage = "An error occurred while archiving the project.";
        }
        this.$refs.modalInfoMessage.showModal();
      } catch (error) {
        this.modalMessage = this.errorReqOcurred;
        this.$refs.modalInfoMessage.showModal();
      } finally {
        this.loading = false;
      }
    },
    openModalToDelete() {
      this.titleCard = 'Project delete';
      this.modalMessage = 'Please note that once you delete a project, all of its dashboards will be deleted as well. If you wish to proceed please press OK, otherwise please press Cancel.';
      this.isOkPopUp = false;
      this.isDeletePopUp = true;
      this.$refs.modalInfoMessage.showModal();
    },
    async deleteProjectById() {
      try {
        this.loading = true;
        const res_data = await deleteProjectById(this.projectSelected.value);
        if(res_data.error) {
          this.showCustomModal('Project deletion error', res_data.message);
          return;
        }

        const msg = 'This project, along with all of its dashboards, has been deleted.'
        this.showCustomModal('Project deleted', msg);
        this.$emit('load-projects-list');

      } catch (error) {
        this.modalMessage = this.errorReqOcurred;
        this.$refs.modalInfoMessage.showModal();
      } finally {
        this.loading = false;
      }
    },
    showModal(modalIndex) {
      const modalRef = this.$refs.modalAndPopUpCardRef[modalIndex];
      modalRef.showModal();
    },
    closePop() {
      this.popup = false;
    },
    toggleActions() {
      this.showActions = this.showActions === true ? false : true;
    },
    closeActions() {
      this.showActions = false;
    },
    renameAction() {
      this.closeActions();
      this.editF();
      this.addFocus();
    },
  },
  watch: {
    projectSelected() {
      this.setText();
      this.showActions = false;
    },
  },
};
</script>
<style scoped>
.inputContent {
  display: flex;
  align-items: center;
}

.name-organisation {
  font-family: Helvetica;
  font-weight: 400;
  font-size: 36px;
  color: var(--primary-color);
}

.input-content-container {
  display: flex;
  align-items: center;
  position: relative;
}

.selectedTitle {
  background-color: rgba(245, 247, 252, 0);
  border: none;
  font-size: 34px;
  font-family: Helvetica;
  font-weight: 400;
  color: #8954ba;
  display: flex;
}

.selectedTitle:focus {
  border-bottom: 1px solid #8954ba;
}


.dots-vertical {
  display: flex;
  flex-direction: column;
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  border-radius: 50%;
  background: transparent;
  transition: all 0.2s;
}
.dots-vertical:hover {
  background: #ebebeb;
  transform: scale(1.1);
}
.dots-vertical:active {
  background: #dadada;
  transform: scale(1.05);
}

.dots-vertical > span {
  border-radius: 50%;
  width: 0.4rem;
  height: 0.4rem;
  margin: 1px;
  background-color: #8954ba;
}

.dots-actions {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid rgba(159, 168, 214, 0.59);
  box-shadow: 0 7px 20px 0 rgba(159, 168, 214, 0.59);
  cursor: default;
  font-size: 13px;
  padding: 7px 0px;
  position: absolute;
  left: 30px;
  top: -3rem;
  text-align: left;
  width: 17rem;
}

.list-actions {
  color: #d9bef3;
  padding: 0px 10px;
}

.actions-top {
  display: flex; 
  justify-content: space-between;
}

.actions-top > p {
  margin: 0;
  margin-bottom: 5px;
}

.dots-actions > p {
  margin: 0;
  padding: 5px 12px;
  color: rgb(139, 139, 139);
}

.dots-actions > p:hover {
  background-color: #e7d2fb;
  cursor: pointer;
}

.tapEdit {
  margin: 0;
  color: #8954ba;
  cursor: pointer;
  font-size: 1.7rem;
}

.tapOptions {
  display: flex;
}

.tapOptions > p {
  border-right: 1px solid #8954ba;
  color: #8954ba;
  cursor: pointer;
  display: flex;
  font-size: 1.7rem;
  margin: 0;
  padding: 0 2rem;
}

.tapOptions > p:hover {
  color: #8954ba81;
}

.tapOptions > p:last-child {
  border-right: none;
}

.btnSave {
  position: absolute;
  color: #8954ba;
  letter-spacing: 2px;
  right: 10px;
  margin-top: 1px;
}

.project-title-input {
  display: flex;
  flex-direction: row !important;
  width: 100%;
  align-items: center;
}

.closeBtn {
  background-color: #fff;
  border: none;
  color: #8954ba;
  cursor: pointer;
  margin: 0;
  padding: 0;
  transform: scale(1.5);
}

.popup-archive-project {
  border: 1px solid rgba(159, 168, 214, 0.59);
  border-radius: 10px;
  background-color: #fff;
  cursor: default;
  bottom: 15rem;
  left: 70rem;
  position: absolute;
  z-index: 1;
  box-shadow: 0 7px 20px 0 rgba(159, 168, 214, 0.59);
}

.popup-archive-project .popup-content {
  padding: 20px;
}

.popup-archive-project .popup-content .popup-info {
  text-align: left;
  font-size: 1.7rem !important;
}

.popup-archive-project .popup-content .popup-info h1 {
  margin: 0;
  color: #8954ba;
  font-size: 1.7rem;
}

.popup-archive-project .popup-content .popup-info .popup-top {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.popup-archive-project .popup-options {
  margin-top: 2rem;
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: 1.7rem;
}

.popup-archive-project .popup-options input {
  border: 1px solid #8954ba;
}

.popup-archive-project .popup-options p {
  font-size: 1.8rem;
  color: #8954ba;
}

.popup-archive-project .popup-options label,
.popup-archive-project .popup-options p {
  cursor: pointer;
}

.popup-archive-project .popup-options label,
.popup-archive-project .popup-options p,
.popup-archive-project .popup-content .popup-info p {
  margin: 0;
}

.position-relative {
  position: relative
}

.cursor-pointer {
  cursor: pointer
}

.line-gray {
  color: rgb(172, 172, 172); 
  margin: 0
}
</style>
