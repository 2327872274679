<template>
  <!-- <div>
    <div
      class="loader-animation"
      v-show="creatingDashboard === true"
      style="margin: 0; padding: 0; height: 21.8rem; width: 400px"
    ></div>
    <div class="framework-thumbnail-tile" v-show="creatingDashboard === false">
      <div class="title-dots-container">
        <h2 class="edit-handle-container" @click="handleEdit">
          {{ userInputSubComp.Title }} asdas dfglmkfn sdlfkj sdlfkj sdlkfjs dflk j
        </h2>
        <section v-if="userInputSubComp.id !== null">
          <div @click="toggleActions" class="dots-vertical">
            <i class="icon-ellipsis-vert icono-options"></i>
          </div>
          <div v-if="showActions == true" class="dots-actions">
            <div class="list-actions">
              <div
                class="actions-top"
                style="display: flex; justify-content: space-between"
              >
                <p>List of actions</p>
                <span @click="closeActions" style="cursor: pointer">x</span>
              </div>
              <hr style="color: rgb(172, 172, 172); margin: 0" />
            </div>
            <router-link
              :to="`/editbenchmetric/${userInputSubComp._id}`"
              class="bench-link"
            >
              <p>Edit dashboard</p>
            </router-link>
            <p @click="archiveActions">
              {{
                showArchived == true
                  ? "Unarchive dashboard"
                  : "Archive dashboard"
              }}
            </p>
          </div>
        </section>
      </div>
      <img
        class="edit-handle-container"
        src="../../public/images/sample_graph_impact.jpg"
        @click="handleEdit"
      />
    </div>
  </div> -->
  <div>

  </div>
</template>

<script>
export default {
  name: "CardImpact",
  components: {
  },
  props: {
    archiveimpact: {
      type: Function,
    },
    saveObjective: {
      type: Function,
    },
    objective: {
      type: Object,
    },
    index: {
      type: Number,
    },
    totalLength: {
      type: Number,
    },
    creatingDashboard: {
      type: Boolean,
    },
    showArchived: {
      type: Boolean,
    },
  },
  data() {
    return {
      userInputSubComp: {
        id: null,
        name: "Select data",
        fundName: "Select fund",
      },
      tempName: "",
      tempFundName: "",
      noOfIndex: this.index + 1,
      layoutSelect: false,
      popup: false,
      onKeyupEvent: false,
      showActions: false,
      modals: [
        {
          title: "Save your selection",
          text: 'Click on "save" on this pop up, to save your metric selection for this dashboard. You will then be directed to a dashboard template, where you can customise your dashboard as you wish',
          isOk: false,
          isSaveObjectives: true,
        },
      ],
    };
  },
  mounted() {
    this.setText();
  },
  methods: {
    setText() {
      this.userInputSubComp = this.objective;
      this.tempName = this.userInputSubComp.name;
      if (this.userInputSubComp._id !== null) {
        this.layoutSelect = true;
      } else {
        this.layoutSelect = false;
      }
      if (Array.isArray(this.userInputSubComp)) {
        if (this.userInputSubComp[0]._id !== null) {
          this.layoutSelect = true;
        } else {
          this.layoutSelect = false;
        }
      }
    },
    handleEdit() {
      if (this.objective._id) {
        this.$router.push(`/editbenchmetric/${this.objective._id}`);
      }
    },
    saveObjectives(userInputSubComp) {
      this.saveObjective(userInputSubComp);
      this.onKeyupEvent = false;
      this.popup = false;
    },
    toggleActions() {
      this.showActions = this.showActions === true ? false : true;
    },
    closeActions() {
      this.showActions = false;
    },
    archiveActions() {
      this.archiveimpact(this.userInputSubComp._id);
      this.closeActions();
    },
  },
  watch: {
    objective() {
      this.setText();
    },
  },
};
</script>

<style scoped>
@import "../assets/styles/components/ObjectiveInputDiv.css";
</style>
