<template>
    <section class="section-styled">
        <div class="title-createdashboard-container">
            <h1 class="edit-project-subtitle">
                Dashboards
            </h1>
            <div class="tab-switcher" v-if="isActiveCurrentAndArchiveSwitch">
                <div @click="switchToCurrent" class="custom-btn">
                    <h2 :class="{ 'active': activeTab === 'current' }">Current</h2>
                </div>
                <div class="tab-switcher-separator"></div>
                <div @click="switchToArchive" class="custom-btn">
                    <h2 :class="{ 'active': activeTab === 'archived' }">Archived</h2>
                </div>
            </div>
        </div>
        <div class="invite-team-members">
            <p class="overflow-visible">
                Create, add and edit your dashboard(s) here. Click on the dashboard template below to create your first
                dashboard.
            </p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'DashboardTabs',
    props: {
        isActiveCurrentAndArchiveSwitch: {
            type: Boolean,
        },
        switchToCurrent: {
            type: Function,
        },
        switchToArchive: {
            type: Function,
        },
        activeTab: {
            type: String,
        }
    },
}
</script>
