<template>
  <section class="container-section-team-members">
    <h1 class="edit-project-subtitle">
      Team members
    </h1>
    <div class="invite-team-members">
      <p>
        Invite team members and funders to manage your project and share your work’s impact.
        <br />
        Use the drop-down options to define their access rights.
      </p>
    </div>
    <h2 class="subtitle-teams-members">
      Invite via email and define access rights
    </h2>
    <div class="Line"></div>
    <template>
      <div>
        <div v-for="(item, index) in newCoworkers" :key="index" class="container-add-coworker-row">
          <div class="container-email-coworker">
            <div class="container-email-text">
              <!-- Email for invite -->
                <input type="text"
                :name="'add-cwkr-' + index"
                :id="'add-cwkr-input-' + index"
                class="email-text"
                placeholder="eg adam.smith@companyname.com"
                autocomplete="off"
                v-model="item.email"
                @input="validateEmail(item.email, index)" />
            </div>
            <v-select v-model="item.role" placeholder="Select role" class="team-members-invite-select" :searchable="false"
              :options="permissions">
              <template #option="{ label, details }">
                <div class="details-container">
                  <strong>{{ label }}</strong>{{ '&nbsp;-&nbsp;' }}{{ details }}
                </div>
              </template>
              <template #selected-option="{ label, details }">
                <div class="details-container">
                  <strong>{{ label }}</strong>{{ '&nbsp;-&nbsp;' }}{{ details }}
                </div>
              </template>
            </v-select>

          </div>
          <div v-if="item.error" class="container-email-validation">
            <span>{{ item.message }}</span>
          </div>

          <div class="container-button-add-coworker">
            <button class="button-send-coworkers" @click="showSendInvitePopUp" :disabled="!emailIsValid">Send</button>
          </div>
        </div>
      </div>
    </template>

    <div class="team-members-section" v-if="coworkers.length > 0">
      <h2 class="subtitle-teams-members">Update members' access rights</h2>
      <div class="Line"></div>
      <div class="items-employes" v-for="item of coworkers" :key="item.id">
        <img v-if="item.image" class="image-employes-icons" :src="item.image" />
        <AliceLogoMark v-else :width="'42px'" :height="'42px'" />
        <div class="employes-teams-members">
          <div class="employes-members-info col">
            <h2>{{ item.name }}</h2>
            <h3>{{ item.email ? item.email.toLowerCase() : '' }}</h3>
          </div>
          <!-- Status invited -->
          <div class="col">
            <span v-if="item.status === 'joined'" class="joined-status txt-status">
              &#10003; {{ getCapitalizedStatus(item.status) }}
            </span>
            <button v-else class='pending-status txt-status' @click="resendInvitation(item)" :disabled="isLoadingResendInvitation">
              &#62; Resend invite
            </button>
          </div>
          <div>
            <!-- Coworkers invited -->
            <v-select v-model="item.role" placeholder="Select role" class="team-members-invite-select" :searchable="false"
              :options="permissions" @input="handleChange(item)">
              <template #option="{ label, details }">
                <div class="details-container">
                  <strong>{{ label }}</strong>{{ '&nbsp;-&nbsp;' }}{{ details }}
                </div>
              </template>
              <template #selected-option="{ }">
                <div class="details-container">
                  <strong>{{ getRoleLabel(item) }}</strong>{{ '&nbsp;-&nbsp;' }}{{ getRoleDetails(item.role) }}
                </div>
              </template>
            </v-select>
          </div>
        </div>
      </div>
      <div class="container-button-add-coworker">
        <button class="button-send-coworkers" @click="handleUpdateCoworker" :disabled="!showSaveButton">Save</button>
      </div>
    </div>

    <PopUpCardModal ref="modalCoworker" :isPopupActive="true" :closePopUp="closePopUp" :titleCard="titleCard"
      :textMsg="modalMessage" :isInviteCoworker="isInviteCoworker" :sendInviteCoworker="handleSaveCoworkers"
      :isOk="isOkPopUp" :isSendingInvite="isSendingInvite" />
  </section>
</template>

<script>
import { createCoworkers, getCoworkers, updateRoleCoworker, resendEmailInvitationCoworker } from "../../services/users";
import AliceLogoMark from "../shared/AliceLogoMark.vue";
import PopUpCardModal from "../shared/PopUpCardModal.vue";
import { directive as clickaway } from 'vue-clickaway';
import { mapGetters } from "vuex";
import { socketStateMain } from "../../services/socket.js";

export default {
  name: "TeamMembersSection",
  directives: {
    clickaway,
  },
  props: {
    selectedProjectId: String,
  },
  components: {
    PopUpCardModal,
    AliceLogoMark
  },
  data() {
    return {
      coworkers: [],
      titleCard: '',
      emailIsValid: null,
      permissions: [
        { value: 'supervisor', label: 'Owner', details: 'can create projects' },
        { value: 'editor', label: 'Manager', details: 'can create dashboards' },
        { value: 'viewer', label: 'Viewer', details: 'can view dashboards' },
      ],
      newCoworkers: [{
        email: "",
        role: { value: 'viewer', label: 'Viewer', details: 'can view dashboards' },
        error: false,
        message: "",
      }],
      loading: false,
      loadingcoworkers: false,
      modalMessage: "",
      emailPattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      isInviteCoworker: false,
      isOkPopUp: false,
      showCoworkers: false,
      showPlusButton: false,
      changedFields: [],
      showSaveButton: false,
      isSendingInvite: false,
      isLoadingResendInvitation: false,
    };
  },
  methods: {
    async resendInvitation(coworker) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.isLoadingResendInvitation = true;
      const res_data = await resendEmailInvitationCoworker({
        email: coworker.email,
        role: coworker.role,
        actual_user: coworker.user_id,
        project: this.selectedProjectId,
      });
      if (res_data.error === true) {
        this.titleCard = 'Error';
        this.isInviteCoworker = false;
        this.isOkPopUp = true;
        this.modalMessage = "An error occurred while adding the coworker.";
        return;
      }

      this.loading = false;
      this.isLoadingResendInvitation = false;
      this.isInviteCoworker = false;
      this.isOkPopUp = true;
      this.titleCard = 'Send invite(s)';
      this.modalMessage = 'Email invite(s) sent successfully.';
      this.$refs.modalCoworker.showModal();
    },
    handleChange(item) {
      // Check if the item is already in the changes array
      const existingChange = this.changedFields.find(change => change.email === item.email);

      // If the item does not exist in the changes array, add it
      if (!existingChange) {
        this.changedFields.push({
          email: item.email,
          newRole: item.role.value,
        });
      } else {
        // If the item already exists in the changes array, update its new role
        existingChange.newRole = item.role.value;
      }
      // Active "Save" button
      this.showSaveButton = true;
    },
    getRoleLabel(item) {
      const selectedRole = item.role;
      if (typeof selectedRole === 'string') {
        const foundRole = this.permissions.find(role => role.value === selectedRole);
        return foundRole ? foundRole.label : '';
      } else if (typeof selectedRole === 'object' && selectedRole.label) {
        return selectedRole.label;
      } else if (typeof selectedRole === 'object' && selectedRole.value) {
        // If selectedRole is an object and has a 'value' property, we attempt to find the corresponding role
        const foundRole = this.permissions.find(role => role.value === selectedRole.value);
        return foundRole ? foundRole.label : '';
      } else {
        return 'No role';
      }
    },
    getRoleDetails(selectedRole) {
      if (typeof selectedRole === 'object' && selectedRole.details) {
        return selectedRole.details;
      } else if (typeof selectedRole === 'string') {
        const foundRole = this.permissions.find(role => role.value === selectedRole);
        return foundRole ? foundRole.details : '';
      } else {
        return '';
      }
    },
    handleInputBlur(email, index) {
      this.validateEmail(email, index);
    },
    showSendInvitePopUp() {
      this.isOkPopUp = false;
      this.isInviteCoworker = true;
      this.titleCard = 'Send invite(s)';
      this.modalMessage = 'Once you press Send (an) invite(s) will be sent to the collaborator(s) you indicated. Once they create their account, their access rights will reflect their role.';
      this.$refs.modalCoworker.showModal();
    },
    closePopUp() {
      this.isPopupActive = false;
      this.$refs.modalCoworker.hide();
    },
    validateEmail(email, index) {
      this.newCoworkers[index].email = email;
      const validEmail = this.emailPattern.test(email);
      const isEmailRepeated = this.coworkers.some(coworker => coworker.email.toLowerCase() === email.toLowerCase());

      const currentEmailSession = this.getUserEmail.toLowerCase();

      const isYourEmail = currentEmailSession === email.toLowerCase();
      if(isYourEmail) {
        this.newCoworkers[index].error = true;
        this.newCoworkers[index].message = "This is you email address.";
        this.emailIsValid = false
        return false;
      }
      
      if(isEmailRepeated) {
        this.newCoworkers[index].error = true;
        this.newCoworkers[index].message = "Email address has already been invited.";
        this.emailIsValid = false
        return false;
      }

      if (validEmail) {
        this.newCoworkers[index].error = false;
        this.newCoworkers[index].message = "";
        this.emailIsValid = true;
        return true;
      } else {
        this.newCoworkers[index].error = true;
        this.newCoworkers[index].message = "Please check the email address.";
        this.emailIsValid = false
        return false;
      }
    },
    async handleAddNewCoworker() {
      this.showPlusButton = true;
      this.showCoworkers = true;
      if(!this.emailIsValid) return;
      // if (this.newCoworkers.length === 1) return;
      // Add new coworker
      this.newCoworkers.push({
        email: "",
        role: { value: 'viewer', label: 'Viewer', details: 'can view dashboards' },
        error: false,
        message: "",
      });
    },

    async handleUpdateCoworker() {
      try {
        if (this.loading) {
          return;
        }

        this.loading = true;
        const res_data = await updateRoleCoworker({
          users: this.changedFields,
          project: this.selectedProjectId,
        });

        if (res_data.error === false) {
          this.titleCard = 'Role updated';
          this.isOkPopUp = true;
          this.isInviteCoworker = false;
          this.modalMessage = "The role for this collaborator has been updated, and an email notification has been sent to them.";
          this.showSaveButton = false;
          this.getCoworkers(this.selectedProjectId);
        } else {
          if (res_data?.message) {
            this.titleCard = 'Role updated';
            this.isOkPopUp = true;
            this.isInviteCoworker = false;
            this.modalMessage = res_data.message;
          } else {
            this.titleCard = 'Error';
            this.isOkPopUp = true;
            this.isInviteCoworker = false;
            this.modalMessage = "An error occurred while updating the coworker.";
          }
        }
        this.$refs.modalCoworker.showModal();
      } catch (error) {

        this.titleCard = 'Error';
        this.isOkPopUp = true;
        this.isInviteCoworker = false;
        this.modalMessage = "An error occurred while making the request.";
        this.$refs.modalCoworker.showModal();
      } finally {
        this.loading = false;
      }
    },
    async handleSaveCoworkers() {
      try {
        if (this.loading) {
          return;
        }
        let valid = true;
        for (let index = 0; index < this.newCoworkers.length; index++) {
          const element = this.newCoworkers[index];
          const validEmail = this.validateEmail(element.email, index);
          if (!validEmail) {
            valid = false;
          }
        }
        if (!valid) {
          return;
        }
        this.loading = true;
        this.isSendingInvite = true;
        const aux_coworkers = this.newCoworkers.map((item) => {
          return { email: item.email, role: item.role.value }
        });
        const res_data = await createCoworkers({
          coworkers: aux_coworkers,
          project: this.selectedProjectId
        });
        if (res_data.error === false) {
          this.isInviteCoworker = false;
          this.isOkPopUp = true;
          this.modalMessage = this.newCoworkers.length == 1 ? "Email invite(s) sent successfully." : "The coworkers has been added.";
          this.newCoworkers = [{
            email: "",
            role: { value: 'viewer', label: 'Viewer', details: 'can view dashboards' },
            error: false,
            message: "",
          }];
          this.getCoworkers(this.selectedProjectId);
        } else {
          if (res_data.validations) {
            for (const validation of res_data.validations) {
              this.newCoworkers[validation.index].error = true;
              this.newCoworkers[validation.index].message = validation.message;
            }
          }
          if (res_data?.message) {
            this.titleCard = 'Coworker';
            this.isInviteCoworker = false;
            this.isOkPopUp = true;
            this.modalMessage = res_data.message;

          } else {
            this.titleCard = 'Error';
            this.isInviteCoworker = false;
            this.isOkPopUp = true;
            this.modalMessage = "An error occurred while adding the coworker.";
          }
        }
        this.isSendingInvite = false;
        this.$refs.modalCoworker.showModal();
      } catch (error) {
        this.titleCard = 'Error';
        this.isOkPopUp = true;
        this.modalMessage = "An error occurred while making the request.";
        this.$refs.modalCoworker.showModal();
      } finally {
        this.loading = false;
      }
    },
    async getCoworkers(project) {
      try {
        if (this.loadingcoworkers) {
          return;
        }
        this.loadingcoworkers = true;
        const res_data = await getCoworkers({ project });
        if (res_data.error === false) {
          this.coworkers = res_data.coworkers;
        } else {
          this.coworkers = [];
        }
      } catch (error) {
        this.coworkers = [];
      } finally {
        this.loadingcoworkers = false;
      }
    },
    getCapitalizedStatus(statusText) {
      if (typeof statusText === 'string' && statusText.length > 0) {
        return statusText.charAt(0).toUpperCase() + statusText.slice(1).toLowerCase();
      } else {
        return '';
      }
    },
    updateUserRoleStatus() {
      if (this.loadingcoworkers) {
        return;
      }
      if (socketStateMain.changeUserJoinStatus.length > 0) {
        const data = socketStateMain.changeUserJoinStatus.shift();
        if (this.selectedProjectId == data?.project_id) {
          this.getCoworkers(this.selectedProjectId);
        }
        console.log("changeUserJoinStatus", data);
      }
      /*const new_impact_percentage = this.impactList.findIndex((item) => item._id == data.idImpact);
      if (new_impact_percentage !== -1) {
        this.impactList[new_impact_percentage].cache_percentage = data.percentage;
        this.impactList[new_impact_percentage].cached = data.cached;
      } else {
        if (this.companySelected.value == null && !this.companySelected?.value) {
          this.loadImpacts();
        } else if (this.companySelected.value == data.companyId) {
          if (this.projectSelected.value == null || this.projectSelected.value == data.idProject) {
            this.loadImpacts();
          }
        }
      }*/
    },
  },
  computed: {
    ...mapGetters(["getUserEmail"]),
    change_user_join_status() {
      return socketStateMain.changeUserJoinStatus;
    },
  },
  watch: {
    change_user_join_status() {
      if (this.change_user_join_status.length > 0) {
        this.updateUserRoleStatus();
      }
    },
    selectedProjectId(newValue) {
      if(newValue) {
        this.getCoworkers(newValue)
      }
    },
    coworkers(newValue) {
      this.coworkers = newValue;
    },
  }
};
</script>

<style>
@import "../../assets/styles/components/editProject/TeamMembersSection.css";
</style>
