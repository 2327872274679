<template>
  <div class="company-title-input">
    <div v-if="objectiveSelected == false">
      <input class="selectedTitle" value="Your organisation's name" disabled />
    </div>
    <div
      @mouseover="saveButtonActive = true"
      @mouseleave="saveButtonActive = false"
      v-if="objectiveSelected == true"
      class="inputContent"
    >
      <h2 class="name-organisation" v-if="!edit">{{userInputSubComp}}</h2>
      <div class="input-content-container">
        <input
          v-if="edit"
          @click="editF"
          type="text"
          @keyup="getText(userInputSubComp, $event)"
          @blur="removeFocus"
          ref="inputTextEditObjective"
          v-model="userInputSubComp"
          class="selectedTitle"
          :placeholder="placeholderText"
          :disabled="edit == false"
        />
        <section v-if="edit == false" class="dots-container">
          <button @click="toggleActions" class="dots-vertical">
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div v-if="showActions == true" class="dots-actions">
            <div class="list-actions">
              <div class="actions-top">
                <p>List of actions</p>
                <span @click="closeActions" class="btn">&#x2715;</span>
              </div>
              <hr class="divider-actions-title" />
            </div>
            <p v-if="companySelected.archived" @click="archiveOrganisation(false)">
              Unarchive organisation
            </p>
            <template v-else>
              <p @click="renameAction">Rename organisation</p>
              <p v-if="currentRole !== 'editor'" @click="archiveOrganisation(true)">Archive organisation</p>
            </template>
            <p v-if="currentRole !== 'editor'" @click="openModalToDelete">Delete organisation</p>
          </div>
        </section>
      </div>
      <div v-if="edit == true">
        <div class="tapOptions">
          <p @click="cancelEdit">Cancel</p>
          <p @click="updateObjectives(userInputSubComp)">Save</p>
        </div>
      </div>
      <section v-if="popup == true" class="popup-archive-project">
        <div class="popup-content">
          <div class="popup-info">
            <div class="popup-top">
              <h1>Archive this organisation</h1>
              <span @click="closePop" class="closeBtn">x</span>
            </div>
            <p>Click on "archive" on this pop up to archive</p>
            <p>this organisation. Once archived, all its</p>
            <p>projects will also be archived and they will</p>
            <p>appear under Archived projects on your</p>
            <p>homepage</p>
          </div>
          <div class="popup-options">
            <label>
              <input type="checkbox" name="show" id="showAgain" /> Don't show me
              again
            </label>
            <p @click="archiveOrganisation()">Archive</p>
          </div>
        </div>
      </section>
    </div>
 
    <PopUpCardModal 
      ref="modalInfoMessage" 
      :isPopupActive="true"
      :closePopUp="closePopUp"
      :titleCard="titleCard"
      :textMsg="modalMessage"
      :isOk="isOkPopUp"
      :isDelete="isDeletePopUp"
      :deleteFileById="deleteCompanyId"
      :isDeletingFile="loading"
    />
  </div>
</template>

<script>
import { deleteCompanyId, updateCompany } from "../../services/company";
import PopUpCardModal from "../shared/PopUpCardModal.vue";

export default {
  props: {
    companySelected: {
      type: Object,
      default: () => ({ "label": null, "value": null, "archived": false, "role": null }),
    },
    currentRole: {
      type: String,
    }
  },
  components: {
    PopUpCardModal,
  },
  data() {
    return {
      userInputSubComp: null,
      originaluserInputSubComp: null,
      optionName: "",
      onKeyupEvent: false,
      edit: false,
      saveButtonActive: false,
      objectiveSelected: false,
      popup: false,
      showActions: false,
      placeholderText: "e.g. Alice In",
      loading: false,
      titleCard: "Organisation",
      modalMessage: "",
      isOkPopUp: true,
      isDeletePopUp: false,
    };
  },
  methods: {
    closePopUp() {
      this.$refs.modalInfoMessage.hide();
    },
    showCustomModal(title, contentMsg) {
      this.titleCard = title;
      this.modalMessage = contentMsg;
      this.isOkPopUp = true;
      this.isDeletePopUp = false;
      this.$refs.modalInfoMessage.showModal();
    },
    openModalToDelete() {
      this.titleCard = 'Organisation delete';
      this.modalMessage = 'Please note that once you delete a organisation, all of its dashboards will be deleted as well. If you wish to proceed please press OK, otherwise please press Cancel.';
      this.isOkPopUp = false;
      this.isDeletePopUp = true;
      this.$refs.modalInfoMessage.showModal();
    },
    async deleteCompanyId() {
      try {
        this.loading = true;
        const res_data = await deleteCompanyId(this.companySelected.value);
        if(res_data.error) {
          this.showCustomModal('Organisation deletion error', res_data.message);
          return;
        }

        const msg = 'This organisation, along with all of its dashboards, has been deleted.'
        this.showCustomModal('Organisation deleted', msg);
        this.updateCompanyList(false, true);

      } catch (error) {
        this.modalMessage = this.errorReqOcurred;
        this.$refs.modalInfoMessage.showModal();
      } finally {
        this.loading = false;
      }
    },
    editF() {
      this.edit = true;
      this.originaluserInputSubComp = this.companySelected.label;
      if (this.optionName === "") {
        this.optionName = this.originaluserInputSubComp;
      }
    },
    setText() {
      this.userInputSubComp = this.companySelected.label;
      if (this.companySelected.value == null) {
        this.objectiveSelected = false;
      } else {
        this.objectiveSelected = true;
      }
    },
    getText(event, keyBoard) {
      if (this.optionName !== event) {
        this.onKeyupEvent = true;
      } else {
        this.onKeyupEvent = false;
      }
      if (keyBoard.key === "Enter") {
        this.updateObjectives(this.userInputSubComp);
        this.onKeyupEvent = false;
      } else if (keyBoard.key === "Escape") {
        this.cancelEdit();
      }
    },
    addFocus() {
      setTimeout(() => {
        if (this.$refs.inputTextEditObjective) {
          this.$refs.inputTextEditObjective.focus();
          this.editF();
        }
      }, 10);
    },
    removeFocus() {
      if (this.saveButtonActive === false) {
        this.cancelEdit();
      }
    },
    cancelEdit() {
      this.edit = false;
      this.onKeyupEvent = false;
      this.userInputSubComp = this.originaluserInputSubComp;
    },
    updateCompanyList(archived, selectLastOrg) {
      this.$emit("load-company-list", archived, selectLastOrg);
    },
    async archiveOrganisation(archived = true) {
      try {
        if (this.loading) return;
        this.loading = true;
        const res_data = await updateCompany(this.companySelected.value, {
          name: this.companySelected.label,
          archived,
        });
        if (res_data.error === false) {
          this.$cookies.remove('lastSelectedOptionCompany');
          this.updateCompanyList(false, true);
          this.$emit('toggle-archived-companies', false)
          this.$emit('toggle-archived-projects', false)
          this.closeActions();
          this.closePop();
          this.edit = false;
          this.onKeyupEvent = false;
          const isArchived = archived ? 'archived' : 'unarchived';
          this.titleCard = `Organisation ${isArchived}`
          this.modalMessage = `Your organisation, along with all of its dashboards have been ${isArchived}. You can switch between Current and Archived projects from the two options above.`;
        } else {
          if (res_data?.message) {
            this.modalMessage = res_data.message;
          } else {
            this.modalMessage = "An error occurred while archiving the organisation.";
          }
        }
        this.$refs.modalInfoMessage.showModal();
      } catch (error) {
        this.modalMessage = "An error occurred while making the request.";
        this.$refs.modalInfoMessage.showModal();
      } finally {
        this.loading = false;
      }
    },
    async updateObjectives(userInputSubComp) {
      try {
        if (this.loading) {
          return;
        }
        this.loading = true;
        const res_data = await updateCompany(this.companySelected.value, {
          name: userInputSubComp,
        });
        if (res_data.error === false) {
          this.onKeyupEvent = false;
          this.edit = false;
          this.originaluserInputSubComp = this.userInputSubComp;
          this.optionName = "";
          this.$emit('company-updated', userInputSubComp);
          this.titleCard = "Organisation name"
          this.modalMessage = "Organisation name successfully updated";
        } else {
          if (res_data?.message) {
            this.modalMessage = res_data.message;
          } else {
            this.modalMessage = "An error occurred while updating the organisation.";
          }
        }
        this.$refs.modalInfoMessage.showModal();
      } catch (error) {
        this.modalMessage = "An error occurred while making the request.";
        this.$refs.modalInfoMessage.showModal();
      } finally {
        this.loading = false;
      }
    },
    closePop() {
      this.popup = false;
    },
    toggleActions() {
      this.showActions = this.showActions === true ? false : true;
    },
    closeActions() {
      this.showActions = false;
    },
    renameAction() {
      this.showActions = false;
      this.editF();
      this.addFocus();
    },
  },
  watch: {
    companySelected() {
      this.setText();
      this.showActions = false;
    },
  },
};
</script>
<style scoped>
.inputContent {
  display: flex;
  align-items: center;
  margin-bottom: 30px;  
}

.name-organisation {
  font-family: Helvetica;
  font-weight: 400;
  font-size: 30px;
  color: var(--primary-color);
}
.input-content-container {
  display: flex;
  align-items: center;
  position: relative;
}

.selectedTitle {
  background-color: rgba(245, 247, 252, 0);
  border: none;
  font-size: 30px;
  font-family: Helvetica;
  font-weight: 400;
  color: #8954ba;
  display: flex;
  width: 100%;
  margin-right: 3rem;
}

.selectedTitle:focus {
  border-bottom: 1px solid #8954ba;
}

.dots-container {
  position: relative;
}

.dots-vertical {
  display: flex;
  flex-direction: column;
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  border-radius: 50%;
  background: transparent;
  transition: all 0.2s;
}
.dots-vertical:hover {
  background: #ebebeb;
  transform: scale(1.1);
}
.dots-vertical:active {
  background: #dadada;
  transform: scale(1.05);
}

.dots-vertical > span {
  border-radius: 50%;
  width: 0.4rem;
  height: 0.4rem;
  margin: 1px;
  background-color: #8954ba;
}

.dots-actions {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid rgba(159, 168, 214, 0.59);
  box-shadow: 0 7px 20px 0 rgba(159, 168, 214, 0.59);
  cursor: default;
  font-size: 13px;
  padding: 7px 0px;
  position: absolute;
  left: 30px;
  top: -3rem;
  text-align: left;
  width: 17rem;
}

.list-actions {
  color: #d9bef3;
  padding: 0px 10px;
}

.actions-top {
  display: flex;
  justify-content: space-between;
}

.actions-top > p {
  margin: 0;
  margin-bottom: 5px;
}

.dots-actions > p {
  margin: 0;
  padding: 5px 12px;
  color: rgb(139, 139, 139);
}

.dots-actions > p:hover {
  background-color: #e7d2fb;
  cursor: pointer;
}

.tapEdit {
  color: #8954ba;
  cursor: pointer;
  font-size: 1.7rem;
  margin: 0;
}

.tapOptions {
  display: flex;
}

.tapOptions > p {
  border-right: 1px solid #8954ba;
  color: #8954ba;
  cursor: pointer;
  display: flex;
  font-size: 1.7rem;
  margin: 0;
  padding: 0 2rem;
}

.tapOptions > p:hover {
  color: #8954ba81;
}

.tapOptions > p:last-child {
  border-right: none;
}

.company-title-input {
  align-items: center;
  display: flex;
  flex-direction: row !important;
  width: 100%;
}

.closeBtn {
  background-color: #fff;
  border: none;
  color: #8954ba;
  cursor: pointer;
  margin: 0;
  padding: 0;
  transform: scale(1.5);
}

.popup-archive-project {
  border: 1px solid rgba(159, 168, 214, 0.59);
  border-radius: 10px;
  background-color: #fff;
  cursor: default;
  bottom: 20rem;
  left: 70rem;
  position: absolute;
  z-index: 1;
  box-shadow: 0 7px 20px 0 rgba(159, 168, 214, 0.59);
}

.popup-archive-project .popup-content {
  padding: 20px;
}

.popup-archive-project .popup-content .popup-info {
  text-align: left;
  font-size: 1.7rem !important;
}

.popup-archive-project .popup-content .popup-info h1 {
  margin: 0;
  color: #8954ba;
  font-size: 1.7rem;
}

.popup-archive-project .popup-content .popup-info .popup-top {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.popup-archive-project .popup-options {
  margin-top: 2rem;
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: 1.7rem;
}

.popup-archive-project .popup-options input {
  border: 1px solid #8954ba;
}

.popup-archive-project .popup-options p {
  font-size: 1.8rem;
  color: #8954ba;
}

.popup-archive-project .popup-options label,
.popup-archive-project .popup-options p {
  cursor: pointer;
}

.popup-archive-project .popup-options label,
.popup-archive-project .popup-options p,
.popup-archive-project .popup-content .popup-info p {
  margin: 0;
}
</style>
