<template>
  <div class="organizationContainerBase">
    <div class="organizationContainerTitle">
      <h3 class="organizationTitle">Your organisation</h3>
      <div class="organizationArchivedContainer">
        <button class="buttonArchived" :class="{ activeArchived: !showArchivedCompanies }" @click="unarchivedCompanies"
          :disabled="loadingCompanies">
          Current
        </button>
        <span class="organizationSeparator"></span>
        <button class="buttonArchived" :class="{ activeArchived: showArchivedCompanies }" @click="archivedCompanies"
          :disabled="loadingCompanies">
          Archive
        </button>
      </div>
    </div>
    <p class="information">
      If you are a consultancy you can create an organisation entry per
      customer.
    </p>

    <b-modal ref="modalOrgContainer" centered size="sm" @hide="modalHidden" hide-footer>
      <div class="modal-content">
        <PopUpCard v-if="isPopupActive" :isPopupActive="isPopupActive" :titleCard="titleCard" :textMsg="textMsg"
        :closePopUp="closePopUp" :isOk="true" />
      </div>
    </b-modal>

    <!-- if there is only one company created, this appearance of the dropdown should be shown -->
    <article v-if="companyList.length == 1" class="container-inputs-org" v-clickaway="closeInput">
      <div class="first-input-btn-add">
        <input type="text" class="objective-text input-text-company-edit" v-model="companyList[0].label" disabled
          @keyup="getText(userInputSubComp, $event)" />
        <input class="add-objective-button" type="button" @click="onHandleWriteCompany" :class="isCreatingCompany ? 'rotate-45' : ''" />
      </div>

      <!-- isCreatingCompany conditions whether the input for writing the name of the project to be created is shown or not -->
      <div class="container-inputTextEditObjective">
        <input v-if="isCreatingCompany" ref="inputTextEditObjective" type="text"
          class="objective-text input-text-company-edit" v-model="userInputSubComp" :placeholder="companyPlaceholder"
          @keyup="getText(userInputSubComp, $event)" 
        />
        <input value="save" type="button" class="btn-save" v-if="onKeyupEvent"
          @click="createCompanies(userInputSubComp)" />
      </div>

    </article>

    <article v-if="companyList.length == 0" class="addNewOption">
      <input ref="inputTextEditObjective" type="text" class="objective-text input-text-company-edit"
          v-model="userInputSubComp" 
          :placeholder="companyPlaceholder" 
          @keyup="getText(userInputSubComp, $event)"
          @focus="companyPlaceholder = ''"
          @blur="companyPlaceholder = originalPlaceholder"
        />

      <input 
        value="Save" 
        type="button" 
        class="btn-save" 
        v-if="onKeyupEvent" 
        @click="createCompanies(userInputSubComp)"  
      />
    </article>
  <!-- if there is more than one company created, this appearance of the dropdown should be shown -->
    <!-- <article v-if="modifyCompanies == true && companyList.length > 1" class="addNewOption container-organisation-inputs"> -->
    <article v-if="companyList.length > 1" class="addNewOption container-organisation-inputs">
      <div class="select-and-button-add">
        <v-select placeholder="All companies" v-model="selectedCompany" :options="companyList" :clearable="false"
          :loading="loadingCompanies" @input="updateSelectedCompany">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <img src="../../assets/Dropdown.svg" />
            </span>
          </template>
        </v-select>

        <button @click="onHandleWriteCompany" v-if="showArchivedCompanies == false" type="button" class="addOption" :class="isCreatingCompany ? 'rotate-45' : ''"></button>
      </div>

      <div v-if="isCreatingCompany" v-clickaway="closeInput">
        <input ref="inputTextEditObjective" type="text" class="objective-text input-text-company-edit"
            v-model="userInputSubComp" 
            :placeholder="companyPlaceholder" 
            @keyup="getText(userInputSubComp, $event)"
        />

        <input value="Save" type="button" class="btn-save" v-if="onKeyupEvent"
          @click="createCompanies(userInputSubComp)" />
      </div>

    </article>

    <article class="objective-vfor-wrapper-subtitle-2" v-if="showArchivedCompanies == true &&
      (companyList.length == 0 || companyList[0].value == null)
      ">
      <h3>Currently there's no archived organisation</h3>
    </article>
    <br />
  </div>
</template>

<script>
import { directive as clickaway } from 'vue-clickaway';
import { createCompany } from "../../services/company";
import PopUpCard from "../shared/PopUpCard.vue";

export default {
  name: "CompanySection",
  directives: {
    clickaway,
  },
  components: {
    PopUpCard,
  },
  props: {
    selected: {
      type: Object,
    },
    companyList: {
      type: Array,
      required: true,
    },
    showArchivedCompanies: {
      type: Boolean,
    }
  },
  data() {
    return {
      loadingCompanies: false,
      modalMessage: "",
      modifyCompanies: true,
      userInputSubComp: null,
      companyPlaceholder: "e.g. Alice Inc",
      originalPlaceholder: "e.g. Alice Inc",
      selectedCompany: {},
      isPopupActive: false,
      titleCard: "Organisation",
      textMsg: "Your organisation has been created. Thank you!",
      isCreatingCompany: false,
      optionName: "",
      onKeyupEvent: false,
    };
  },
  async mounted() {
    if (this.$cookies.isKey('lastSelectedOptionCompany')) {
      const lastSelectedOptionCompany = this.$cookies.get('lastSelectedOptionCompany');
      this.selectedCompany = lastSelectedOptionCompany;
    }
    this.loadCompanies();
  },
  methods: {
    modalHidden() {
      document.removeEventListener('keydown', this.handleKeydown);
      this.$emit("active-project-input-focus", true);
    },
    handleKeydown(event) {
      if(event.key === 'Enter') {
        this.isPopupActive = false;
        this.$refs.modalOrgContainer.hide();
      }
    },
    closeInput() {
      this.isCreatingCompany = false;
    },
    loadCompanies(archived = false, selectLastOrg = false) {
      this.$emit("load-company-list", archived, selectLastOrg);
    },
    async createCompanies(company) {
      try {
        if (this.loadingCompanies) {
          return;
        }
        this.loadingCompanies = true;
        const res_data = await createCompany({
          name: company,
          order: this.companyList.length + 1,
        });
        this.onKeyupEvent = false;
        if (res_data.error === false) {
          this.isPopupActive = true;
          this.titleCard = "Organisation created";
          this.textMsg = "Your organisation has been created. Thank you!";
          this.loadCompanies(false, true);
          this.userInputSubComp = null;
          this.$refs.modalOrgContainer.show();
          // this.modifyCompanies = false;
          this.isCreatingCompany = false;
        } else {
          if (res_data?.message) {
            this.textMsg = res_data.message;
            this.isPopupActive = true;
            this.$refs.modalOrgContainer.show();
          } else {
            this.textMsg =
              "An error occurred while creating the organization.";
          }
        }
      } catch (error) {
        this.textMsg = "An error occurred while making the request.";
      } finally {
        this.loadingCompanies = false;
      }
    },
    closePopUp() {
      this.isPopupActive = false;
      this.$refs.modalOrgContainer.hide();
      this.$emit("active-project-input-focus", true);
    },
    onHandleEditCompany() {
      this.modifyCompanies = true;
    },
    onHandleWriteCompany() {
      this.$emit("active-project-input-focus", false);
      this.isCreatingCompany = !this.isCreatingCompany;
      if(this.isCreatingCompany) {
        this.$nextTick(() => {
        this.$refs.inputTextEditObjective.focus();
      });
      }
    },
    getText(event, keyBoard) {
      if (this.optionName !== event) {
        this.onKeyupEvent = true;
      } else {
        this.onKeyupEvent = false;
      }
      if (keyBoard.key === "Enter") {
        this.createCompanies(
          this.userInputSubComp,
        );
        this.onKeyupEvent = false;
        // this.isCreatingCompany = false;
        this.userInputSubComp = null;
      } else if (keyBoard.key === "Escape") {
        this.cancelEdit();
      }
    },

    cancelEdit() {
      this.edit = false;
      this.onKeyupEvent = false;
      this.isCreatingCompany = false;
      // this.userInputSubComp = this.originaluserInputSubComp;
    },

    unarchivedCompanies() {
      if (this.showArchivedCompanies == false) {
        return;
      }
      this.$emit('toggle-archived-companies', false);
      // FIXME: This fixes the error that occurs when navigating between the 'current' and 'Archive' options. 
      this.loadCompanies(false, true);
      // this.updateSelectedCompany({ label: "", value: null, archived: null });
    },
    archivedCompanies() {
      if (this.showArchivedCompanies == true) {
        return;
      }
      this.$emit('toggle-archived-companies', true);
      this.loadCompanies(true);
      this.modifyCompanies = false;
      this.updateSelectedCompany({ label: "", value: null, archived: null });
    },
    addRow(userInputSubComp) {
      this.companyList.splice(userInputSubComp.noOfIndex, 0, {
        label: "",
        value: null,
        archived: null,
      });
    },
    updateSelectedCompany(newValue) {
        if(newValue) {
          this.$cookies.set('lastSelectedOptionCompany', newValue);
        } 
        this.selectedCompany = newValue;
        this.$emit("selected-company-changed", newValue);
    },
  },
  watch: {
    selected() {
      this.selectedCompany = this.selected;
    },
    isPopupActive(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          document.addEventListener('keydown', this.handleKeydown);
        });
      }
    }
  }
};
</script>
<style src="@/assets/styles/components/editProject/CompanySection.css"></style>
