<template>
  <div class="card-container">
    <div class="card-header-container">
      <span class="card-title-impact">
        {{ impact.Title }}
      </span>
      <div v-if="impact._id != null && currentRole !== 'viewer'" class="dropdown" :class="{ active: showActions }" v-click-outside="closeActions">
        <button class="dropbtn" @click="toggleActions">
          <i class="icon-ellipsis-vert icono-options"></i>
        </button>
        <div class="dropdown-content">
          <span class="dropdown-title">List of actions</span>
          <button v-if="!impact.archived" @click="handleArchive">Archive dashboard</button>
          <button v-if="!impact.archived && currentRole !== 'editor'" @click="openModalToDelete">Delete dashboard</button>
          <button v-if="impact.archived" @click="handleUnarchive">Restore dashboard</button>
        </div>
      </div>
    </div>
    <div class="card-content-container" @click="handleEdit">
      <img
        class="card-impact-image"
        src="../../../public/images/sample_graph_impact.jpg"
      />
    </div>
  </div>
</template>

<script>
import clickOutsideDirective from '../../utils/clickOutsideDirective.js';

export default {
  name: "CardImpact",
  components: {},
  directives: {
    'click-outside': clickOutsideDirective
  },
  props: {
    impact: {
      type: Object,
    },
    activeTab: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    currentRole: {
      type: String,
    }
  },
  data() {
    return {
      showActions: false,
    };
  },
  methods: {
    openModalToDelete() {
      this.$emit('open-modal-to-delete', this.impact._id);
    },
    handleEdit() {
      if (this.impact._id !== null && this.activeTab !== 'archived') {
        this.$router.push({
          name: 'editbenchmetric',
          params: { id: this.impact._id, editing: true },
        });
        return;
      }
      if (this.impact._id === null && this.activeTab !== 'archived' && this.disabled) {
        this.$emit("handleAddImpact");
      }
    },
    handleArchive() {
      if (this.impact._id != null) {
        this.$emit("handleArchive", this.impact._id);
      }
    },
    handleUnarchive() {
      if (this.impact._id === null) return;
      this.$emit("handleArchive", this.impact._id, false);
    },
    toggleActions() {
      this.showActions = this.showActions === true ? false : true;
    },
    closeActions() {
      this.showActions = false;
    },
  },
};
</script>

<style scoped>
@import "../../assets/styles/components/CardImpact.css";
</style>
