<template>
  <div class="edit-project-options">
    <div>
      <h1 class="edit-project-title title-active">Your metrics</h1>
    </div>
    <div class="container-edit-project">
      <div class="container-edit-project-text">
        <h1 class="edit-project-title title-rewards">Your rewards</h1>
        <span class="badge">Coming soon!</span>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "MainTabs",
  data() {
    return {}
  },
};
</script>
