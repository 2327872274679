<template>
  <div class="framework-form-area">
    <form class="dropdown-form">
      <div class="framework-dropdown">
        <button type="button" class="framework-dropdown-toggle" @click="FrameworkToggleDropdown">
          {{selectboxPlaceholder}}
        </button>
        <ul class="framework-dropdown-menu">
          <li class="framework-dropdown-item" v-for="(option, index) in selectList" v-bind:key="index" @click="FrameworkToggleDropdown">
            <button type="button" :value="option" class="framework-dropdown-option" @click="getFrameworkText(option)">
              {{option}}
            </button>
          </li>
        </ul>
      </div>
    </form>
    <form class="add-link-form" v-if="showAddLinkArea">
      <h3 class='framework-sub-title'>Add a link to the framework you’re using</h3>
      <!-- <input class='text-form' type='text' placeholder="https://www.sopact.com/social-impact-measurement-framework"> -->
      <div v-for="(link, index) in links" v-bind:key="index" class="objective-input-wrapper">
        <framework-link-input
          @add-objectives="addFrameworks"
          @remove-objectives="removeObjectives"
          :objective="link"
          :index="index"
          :placeholderText="placeholderText"
          :totalLength="noOfObjInputForm"
          v-bind:class="index"
        ></framework-link-input>
      </div>
    </form>
    <button class='text-submit' @click="saveFrameworksData">Add</button>
  </div>
</template>

<script>
import FrameworkLinkInput from '../components/ObjectiveInputDiv'
export default {
  name: 'framework',
  components: {
    FrameworkLinkInput
  },
  data () {
    return {
      selectList: [
        'No framework',
        'SDG - Sustainable Development Goals',
        'SROI - Social Return on Investment',
        'ESG - Environmental, Social, Governance',
        'IRIS - Impact Reporting & Investment Standards',
        'GIIRS - Global Impact Investing Rating System',
        'Other framework'],
      selectedOption: '',
      placeholderText: ["https://www.sopact.com/social-impact-measurement-framework"],
      links: [""],
      selectboxPlaceholder: 'No framework',
      showAddLinkArea: false
    }
  },
  mounted () {

  },
  methods: {
    FrameworkToggleDropdown () {
      const menu = document.querySelector('.framework-dropdown-menu')
      menu.classList.toggle('show')
    },
    getFrameworkText (text) {
      this.selectboxPlaceholder = text
      if (text === 'Other framework') {
        this.showAddLinkArea = true
      } else {
        this.showAddLinkArea = false
      }
    },
    addFrameworks (addedFramework) {
      this.links.unshift(addedFramework.userInputSubComp)
    },
    removeObjectives (removedFramework) {
      const editedIndex = removedFramework.noOfIndex - 1
      this.links.splice(editedIndex, 1)
    },
    saveFrameworksData () {
      const frameworksName = this.selectboxPlaceholder
      const addedLinks = this.links
      this.$emit('save-framework-data', { frameworksName, addedLinks })
    }
  },
  watch: {
  },
  computed: {
    noOfObjInputForm () {
      return this.links.length
    }
  }
}
</script>

<style src="@/assets/styles/components/Framework.css"></style>
