<template>
  <div class="button-container">
    <button 
    class="button-add" @click="handleAdd">+</button>
    <!----v-if="((!isSubscribed && impactsCount == 0) || (isSubscribed))"-->
  </div>
</template>

<script>
export default {
  name: "CardAddImpact",
  props: {
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      isSubscribed: false
    };
  },
  methods: {
    handleAdd() {
      if(this.disabled) {
        this.$emit("handleAddImpact");
        return;
      }
      this.$emit("showCsvUploadMessage");
    },
  },
  mounted(){
    let suscrito = localStorage.getItem("user_data")
    suscrito = JSON.parse(suscrito)
    suscrito = suscrito.suscription
    this.isSubscribed = suscrito
  }
};
</script>

<style scoped>
@import "../../assets/styles/components/AddCardImpact.css";
</style>
