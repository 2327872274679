/* eslint-disable vue/no-unused-components */

<template>
  <div>
    <main id="edit-proejct">
      <div id="nav">
      </div>
      <div class="back">
        <router-link to="/">
          <img src="../../src/assets/BackArrow.svg" alt="back-arrow" /> Back
        </router-link>
      </div>
      <section class="your-project">
        <MainTabs />
        <CompanySection :selected="selectedCompany" @selected-company-changed="updateSelectedCompany"
          @active-project-input-focus="activeProjectInputFocus" :companyList="companyList"
          @load-company-list="loadCompanyList" @toggle-archived-projects="toggleArchivedProjectsView"
          @toggle-archived-companies="toggleArchivedCompaniesView" :showArchivedCompanies="showArchivedCompanies" />
        <ProjectSection :selected="selectedProject" :companySelected="selectedCompany"
          @selected-project-changed="updateSelectedProject" @active-project-input-focus="activeProjectInputFocus"
          :projectCreationInputFocus="projectCreationInputFocus" @load-projects-list="loadProjectList"
          :projectList="projectList" :loadingProjects="loadingProjects" :withoutProjects="withoutProjects"
          @toggle-archived-projects="toggleArchivedProjectsView" :showArchivedProjects="showArchivedProjects"
          @toggle-archived-companies="toggleArchivedCompaniesView" :showArchivedCompanies="showArchivedCompanies"
          :currentRole="currentRole" />
      </section>


      <article class="article-container"
        v-show="currentRole === 'owner' || currentRole === 'editor' || currentRole === 'supervisor'">
        <CompanyTitleInput :currentRole="currentRole" :companySelected="selectedCompany"
          @company-updated="handleCompanyUpdated" :impactList="impactList" @load-company-list="loadCompanyList"
          @toggle-archived-companies="toggleArchivedCompaniesView"
          @toggle-archived-projects="toggleArchivedProjectsView" />
        <ProjectTitleInput :currentRole="currentRole" :projectSelected="selectedProject"
          :companySelected="selectedCompany" @load-projects-list="loadProjectList" :projectList="projectList"
          @toggle-archived-projects="toggleArchivedProjectsView" @project-updated="handleProjectUpdated" />
        <h1 class="file-upload-area-title">
          Data link
        </h1>
      </article>

      <template v-if="currentRole === 'owner' || currentRole === 'editor' || currentRole === 'supervisor'">
        <section class="upload-module-area">
          <upload-module :company="selectedCompany" :selectedProject="selectedProject" @idFiles="idFiles"
            @consultHeaders="makeReqWhenAddFile" :listUploadedFiles="listUploadedFiles"
            :showArchivedCompanies="showArchivedCompanies" :showArchivedProjects="showArchivedProjects"></upload-module>
        </section>


        <!-- Current | Archived -->
        <DashboardTabs :isActiveCurrentAndArchiveSwitch="isActiveCurrentAndArchiveSwitch"
          :switchToCurrent="switchToCurrent" :switchToArchive="switchToArchive" :activeTab="activeTab" />

        <div class="popup-archived-container">
          <PopUpCardModal ref="modalInfoMessage" :titleCard="titleCard" :textMsg="modalMessage" :closePopUp="closePopUp"
            :isOk="isOkPopUp" :isDelete="isDeletePopUp" :isPopupActive="isPopupActive"
            :cancelFileDeletion="cancelFileDeletion" :deleteFileById="deleteImpactById"
            :isDeletingFile="isDeletingFile" />
        </div>

        <div v-if="isCompanyAndProjectSelected && (!showArchivedCompanies && !showArchivedProjects)">
          <loading-spinner v-if="loadingImpacts" />

          <div v-else>

            <!-- Display this when no dashboard has been created -->
            <div @click="showCsvUploadMessage" v-if="impactList.length === 0" class="card-impact-container">
              <CardImpact :impact="{ _id: null, Title: 'Dashboard Title' }" @handleArchive="archiveimpact"
                @handleAddImpact="handleAddImpact" :activeTab="activeTab"
                :disabled="listUploadedFiles.length > 0 && activeTab !== 'archived'" class="card-impact-example" />
              <div class="btn-plus-container">
                <!-- Plus button -->
                <CardAddImpact @handleAddImpact="handleAddImpact"
                  :disabled="listUploadedFiles.length > 0 && activeTab !== 'archived'"
                  @showCsvUploadMessage="showCsvUploadMessage" />
              </div>
            </div>

            <!-- Display this when dashboards have already been created  -->
            <Splide v-else id="splidwwe" :options="splideOptions" :slides="objectivesOrdered">
              <SplideSlide v-for="(impact, index) in reversedImpactList" :key="index" class="container-splide">
                <CardImpact :impact="impact" @handleArchive="archiveimpact" :activeTab="activeTab"
                  @showArchivedDashboardModal="showArchivedDashboardModal" @open-modal-to-delete="openModalToDelete"
                  :currentRole="currentRole" />
              </SplideSlide>
              <SplideSlide>
                <!-- Plus button -->
                <CardAddImpact @handleAddImpact="handleAddImpact"
                  :disabled="listUploadedFiles.length > 0 && activeTab !== 'archived'"
                  @showCsvUploadMessage="showCsvUploadMessage" />
              </SplideSlide>
            </Splide>
          </div>
        </div>

        <TeamMembersSection :selectedProjectId="selectedProject.value"
          v-if="currentRole === 'owner' || currentRole === 'supervisor'" />

        <framework-select-box class="d-none">
        </framework-select-box>
      </template>

      <b-modal ref="modalFirstLoggin" size="sm" hide-footer modal-class="opaque-gradient">
        <PopUpCard :titleCard="'Welcome!'" :textMsg="emailVerifyMsgPopUp" :isPopupActive="true" :closePopUp="closePopUp"
          :isOk="true" :min-width="'350px'" />
      </b-modal>

      <b-modal class="modal-loading-process" ref="modal-loading-process" id="modal-loading-process" size="sm" centered
        no-close-on-backdrop no-close-on-esc hide-footer>
        <div class="d-flex flex-column align-items-center">
          <loading-spinner />
          <label class="label-loading-text">
            Generating dashboard...
          </label>
        </div>
      </b-modal>

      <b-modal ref="modalCookiesAccepted" size="sm" centered hide-footer v-if="!cookiesAccepted">
        <div class="label-container">
          <h3 class="label-text">
            <h4>This website uses cookies to improve your browsing experience</h4>
          </h3>
          <button id="finishEditProject" class="ok-button" @click="acceptCookies">
            Ok
          </button>
        </div>
      </b-modal>
    </main>
  </div>
</template>

<script>
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import MainTabs from "../components/EditProject/MainTabs.vue";
import UploadModule from "../components/UploadModule.vue";
import CardImpact from "../components/EditProject/CardImpact.vue";
import CardAddImpact from "../components/EditProject/CardAddImpact.vue";
import FrameworkSelectBox from "../components/Framework.vue";
import ProjectTitleInput from "../components/EditProject/ProjectTitleInput.vue";
import CompanyTitleInput from "../components/EditProject/CompanyTitleInput.vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import LoadingSpinner from "../components/LoadingSpinner.vue";
import CompanySection from "../components/EditProject/CompanySection.vue";
import ProjectSection from "../components/EditProject/ProjectSection.vue";
import { archiveImpact, deleteImpactById, getImpactByProjectId } from "../services/impact.js";
import { getCurrentYears } from "../services/impact";
import { saveWelcome } from "../services/users";
import TeamMembersSection from '../components/EditProject/TeamMembersSection.vue';
import { getCompaniesByGroup } from "../services/company";
import { getProjectsByCompanyId } from "../services/project";
import PopUpCardModal from "../components/shared/PopUpCardModal.vue";
import PopUpCard from "../components/shared/PopUpCard.vue";
import DashboardTabs from "../components/EditProject/DashboardTabs.vue";

export default {
  components: {
    MainTabs,
    CardImpact,
    CardAddImpact,
    UploadModule,
    FrameworkSelectBox,
    ProjectTitleInput,
    CompanyTitleInput,
    Splide,
    SplideSlide,
    LoadingSpinner,
    CompanySection,
    ProjectSection,
    TeamMembersSection,
    PopUpCardModal,
    PopUpCard,
    DashboardTabs
  },
  data() {
    return {
      companyList: [],
      projectList: [],
      selectedCompany: { label: "", value: null, archived: null },
      selectedProject: { label: "", value: null, archived: null },
      impactList: [],
      modalMessage: "",
      loadingImpacts: true,
      loadingYears: false,
      selectXls: [],
      listUploadedFiles: [],
      idImpact: null,
      cookiesAccepted: false,
      projectCreationInputFocus: false,
      emailVerifyMsgPopUp: `Thank you for joining our platform!<br/> You can now create your projects and see the impact of your work!`,
      activeTab: 'current',
      loadingProjects: true,
      withoutProjects: false,
      showArchivedProjects: false,
      showArchivedCompanies: false,
      titleCard: '',
      isPopupActive: false,
      isOkPopUp: true,
      isDeletePopUp: false,
      idImpactToDelete: null,
      updatedImpactList: null,
      currentRole: 'owner', // owner supervisor, editor, viewer
      isDeletingFile: false,
      commonMessageError: 'An error occurred while making the request.',
    };
  },
  async mounted() {
    if (!(localStorage.getItem("cookiesAccepted") !== null)) {
      this.$refs.modalCookiesAccepted.show();
    }
    if (this.$cookies.isKey('lastSelectedOptionCompany')) {
      const lastSelectedOptionCompany = this.$cookies.get('lastSelectedOptionCompany');
      this.selectedCompany = lastSelectedOptionCompany;
    }
    this.activateWelcome();
  },
  methods: {
    showCsvUploadMessage() {
      if (!this.listUploadedFiles.length <= 0) return;
      this.titleCard = 'CSV upload required';
      this.modalMessage = 'Please upload a CSV in the section above to be able to create your first dashboard.'
      this.$refs.modalInfoMessage.showModal();
    },
    openModalToDelete(idImpact) {
      this.idImpactToDelete = idImpact;
      this.titleCard = 'Dashboard delete';
      this.modalMessage = 'If you wish to delete this dashboard please press OK, otherwise please press Cancel.';
      this.isOkPopUp = false;
      this.isDeletePopUp = true;
      this.$refs.modalInfoMessage.showModal();
    },
    async deleteImpactById() {
      try {
        if (!this.idImpactToDelete) return;
        this.loading = true;
        this.isDeletingFile = true;
        const data = await deleteImpactById(this.idImpactToDelete);
        if (data.error === false) {
          this.isDeletePopUp = false;
          this.titleCard = 'Dashboard deleted';
          this.modalMessage = 'The dashboard has been deleted. The active dashboards appear in the Dashboards section of this page.';
          this.isOkPopUp = true;
          this.$refs.modalInfoMessage.showModal();
          this.getImpactByProjectId(this.selectedProject.value, false);
        }
      } catch (error) {
        this.modalMessage = error.message || this.commonMessageError;
      } finally {
        this.loading = false;
        this.isDeletingFile = false;
      }
    },
    cancelFileDeletion() {
      this.isPopupActive = false;
    },
    showArchivedDashboardModal() {
      this.titleCard = `Dashboard archived`
      this.modalMessage = 'The dashboard is archived, it cannot be edited.';
      this.isPopupActive = true;
    },
    toggleArchivedCompaniesView(status) {
      if (this.showArchivedCompanies === status) return;
      this.showArchivedCompanies = status;
    },
    toggleArchivedProjectsView(status) {
      if (this.showArchivedProjects === status) return;
      this.showArchivedProjects = status;
    },
    async loadCompanyList(archived = false, selectLastOrg = false) {
      try {
        this.loadingCompanies = true;
        const res_data = await getCompaniesByGroup({
          archived,
        });
        if (res_data.error === false) {
          if (res_data.companies.length === 0) {
            this.selectedProject = { label: "", value: null, archived: null };
            this.selectedCompany = { label: "", value: null, archived: null };
            this.updateSelectedCompany(this.selectedCompany);
            this.getCurrentYears();
          }

          const list_aux = res_data.companies.map((x) => {
            return {
              label: x.name,
              value: x._id,
              archived: x.archived,
            };
          });
          if (list_aux.length === 1) {
            this.updateSelectedCompany(list_aux[0]);
            this.$cookies.set('lastSelectedOptionCompany', list_aux[0]);
          }

          if (list_aux && list_aux.length > 0) {
            // when you log out and log back in, the last option created is selected by default
            if (JSON.stringify(this.selectedCompany) === "{}" ||
              (
                this.selectedCompany.archived === null &&
                this.selectedCompany.label === '' &&
                this.selectedCompany.value === null)
            ) {
              this.updateSelectedCompany(list_aux[list_aux.length - 1]);
            }
            this.companyList = list_aux.reverse();
            // Select the last added organisation
            if (selectLastOrg) {
              this.selectedCompany = list_aux[0];
            }
            this.updateSelectedCompany(this.selectedCompany);
          } else {
            if (archived === false) {
              this.companyList = [];
              this.modifyCompanies = true;
            } else {
              this.modifyCompanies = false;
              this.companyList = [];
            }
          }
        } else {
          this.companyList = [];
        }
      } catch (error) {
        this.companyList = [];
      } finally {
        this.loadingCompanies = false;
      }
    },
    // FIXME: requests multiples
    async loadProjectList(archived = false, selectLastProj = false) {
      try {
        this.loadingProjects = true;
        const res_data = await getProjectsByCompanyId(
          this.selectedCompany.value,
          {
            archived,
          }
        );

        if (res_data.error === false) {
          if (res_data.projects.length === 0) {
            this.withoutProjects = true;
            this.selectedProject = { label: "", value: null, archived: null };
            this.$cookies.set('lastSelectedOptionProject', this.selectedProject)
            this.projectList = [];
            return;
          }

          const list_aux = res_data.projects.map((x) => {
            return {
              label: x.name,
              value: x._id,
              archived: x.archived,
              isOwner: x.is_owner,
              role: x.role
            };
          });

          const lastSelectedOptionProject = this.$cookies.get('lastSelectedOptionProject');
          if (list_aux.length === 1) {
            this.selectedProject = list_aux[0];
            this.updateSelectedProject(this.selectedProject);
            this.$cookies.set('lastSelectedOptionProject', list_aux[0])
          }

          this.projectList = list_aux.reverse();

          if (archived) {
            this.selectedProject = list_aux[0];
            this.updateSelectedProject(this.selectedProject);
            return
          }

          if (lastSelectedOptionProject !== null) {
            const projectExist = list_aux.filter((project) => project.value === lastSelectedOptionProject.value).length > 0;
            if (!projectExist) {
              this.selectedProject = list_aux[0];
              this.updateSelectedProject(this.selectedProject);
              this.$cookies.set('lastSelectedOptionProject', this.selectedProject);
              return;
            }
          } else {
            this.selectedProject = list_aux[0];
            this.updateSelectedProject(this.selectedProject);
            this.$cookies.set('lastSelectedOptionProject', this.selectedProject);
            return;
          }

          if (list_aux && list_aux.length > 0) {
            if (this.$cookies.isKey('lastSelectedOptionProject')) {
              const lastSelectedOptionProject = this.$cookies.get('lastSelectedOptionProject');
              this.selectedProject = lastSelectedOptionProject;
              this.$cookies.set('lastSelectedOptionProject', this.selectedProject);
            }
            if (JSON.stringify(this.selectedProject) === "{}") {
              this.updateSelectedProject(list_aux[0]);
            }

            // Select the last added project
            if (selectLastProj) {
              this.selectedProject = list_aux[0];
              this.$cookies.set('lastSelectedOptionProject', this.selectedProject);
            }

            this.updateSelectedProject(this.selectedProject);
            this.loadingImpacts = false;
            return;
          } else {
            if (archived === false && this.companySelected.archived == false) {
              this.firstProject = true;
              this.projectList = [];
              this.modifyProjects = true;
            } else {
              this.firstProject = false;
              this.modifyProjects = false;
              this.projectList = [];
            }
            this.updateSelectedProject({ label: "", value: null, archived: null });
          }
        } else {
          this.projectList = [];
        }
      } catch (error) {
        this.projectList = [];
      } finally {
        this.loadingProjects = false;
      }
    },
    switchToCurrent() {
      if (this.activeTab === 'current') return;
      this.activeTab = 'current';
      this.getImpactByProjectId(this.selectedProject.value, false);
    },
    switchToArchive() {
      if (this.activeTab === 'archived') return;
      this.activeTab = 'archived';
      this.getImpactByProjectId(this.selectedProject.value, true);
    },
    activateWelcome() {
      const welcome = this.$route?.query?.welcome;
      if (welcome) {
        this.$router.replace({ query: { ...this.$route.query, welcome: undefined } });
        this.$refs.modalFirstLoggin.show();
        saveWelcome();
      }
    },
    handleCompanyUpdated(updatedValue) {
      this.selectedCompany.label = updatedValue;
    },
    handleProjectUpdated(updatedValue) {
      this.selectedProject.label = updatedValue;
    },
    closePopUp() {
      this.isPopupActive = false;
      this.$refs.modalFirstLoggin.hide();
    },
    handleAddImpact() {
      this.$router.push({
        name: 'editbenchmetric',
        params: {
          creating: true,
        },
        query: {
          company: this.selectedCompany.value,
          project: this.selectedProject.value,
        }
      });
    },
    activeProjectInputFocus(isActive) {
      this.projectCreationInputFocus = isActive;
    },
    updateSelectedCompany(company) {
      if (company) {
        this.$cookies.set('lastSelectedOptionCompany', company);
      }
      this.selectedCompany = company;
    },
    updateSelectedProject(project) {
      this.selectedProject = { label: "", value: null, archived: null };
      if (!project) return;
      this.impactList = [];
      this.selectedProject = project;
      this.$cookies.set('lastSelectedOptionProject', project)
      this.getImpactByProjectId(project.value);
      this.getCurrentYears();
    },
    async getCurrentYears() {
      if (this.loadingYears) return;
      if (this.selectedCompany.value == null || this.selectedProject.value == null) {
        this.listUploadedFiles = [];
        return;
      }
      try {
        this.loadingYears = true;
        const res_data = await this.fetchYearsFromDB();
        if (res_data.error === false) {
          this.listUploadedFiles = res_data.years;
        } else {
          this.listUploadedFiles = [];
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loadingYears = false;
      }
    },
    async fetchYearsFromDB() {
      return getCurrentYears({
        idcompany: this.selectedCompany.value,
        idproject: this.selectedProject.value,
      });
    },
    handleError(error) {
      console.error('Error:', error);
    },
    async getImpactByProjectId(project, isArchived = false) {
      if (this.selectedCompany.value == null || this.selectedProject.value == null) return;
      try {
        this.loadingImpacts = true;
        const res_data = await this.fetchImpactByProjectId(project, isArchived);

        if (res_data.error === false) {
          this.impactList = res_data.impacts;
          this.loadingImpacts = false;
          return;
        }

        this.handleImpactError(res_data);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loadingImpacts = false;
      }
    },
    async fetchImpactByProjectId(project, isArchived) {
      return getImpactByProjectId(project, { archived: isArchived });
    },
    async archiveimpact(impact, archive = true) {
      try {
        this.loadingImpacts = true;
        const res_data = await this.performArchiveImpact(impact, archive);

        if (res_data.data.error === false) {
          this.updateImpactList(impact);
          this.handleArchiveSuccess(archive);
          return;
        }

        this.handleArchiveError(res_data.data);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loadingImpacts = false;
      }
    },
    async performArchiveImpact(impact, archive) {
      return archiveImpact(impact, archive);
    },
    updateImpactList(impactId) {
      this.impactList = this.impactList.filter(impactProject => impactProject.idImpact !== impactId);
    },
    handleArchiveSuccess(archive) {
      const isArchived = archive ? 'archived' : 'unarchived';
      this.titleCard = `Dashboard ${isArchived}`;
      this.modalMessage = `The dashboard has been ${isArchived}. You can switch between Current and Archived dashboards from the two options above.`;
      this.isPopupActive = true;
      this.$refs.modalInfoMessage.showModal();
    },
    handleArchiveError(res_data) {
      this.titleCard = 'Error';
      this.modalMessage = res_data.message || this.commonMessageError;
      this.$refs.modalInfoMessage.showModal();
    },
    makeReqWhenAddFile() {
      this.getCurrentYears();
    },
    idFiles(idFiles) {
      this.selectXls = idFiles.selected;
    },
    acceptCookies() {
      localStorage.setItem('cookiesAccepted', true);
      this.cookiesAccepted = true;
    }
  },
  computed: {
    reversedImpactList() {
      return this.impactList.slice().reverse();
    },
    splideOptions() {
      return {
        type: 'slide',
        perPage: 3,
        perMove: 1,
        rewind: false,
        speed: 200,
        start: this.impactList.length - 2 <= 0 ? 0 : this.impactList.length - 2,
        pagination: false,
        trimSpace: false,
        gap: '3.3%',
        width: '100%',
        breakpoints: {
          767: {
            perPage: 1,
          },
          1366: {
            perPage: 2,
          },
        },
        classes: {
          prev: 'splide__arrow--prev material-icons-left',
          next: 'splide__arrow--next material-icons-right',
        },
        arrows: this.impactList.length > 2,
      }
    },
    isActiveCurrentAndArchiveSwitch() {
      return this.listUploadedFiles.length > 0 && this.isCompanyAndProjectSelected && (!this.showArchivedCompanies && !this.showArchivedProjects)
    },
    isCompanyAndProjectSelected() {
      return this.selectedCompany.value && this.selectedProject.value ? true : false;
    },
    objectivesOrdered() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      const ordered = this.impactList.sort((a, b) => {
        try {
          if (typeof a.date !== "undefined" && typeof b.date !== "undefined") {
            let date_a = a.date;
            let date_b = b.date;
            let fa = new Date(date_a);
            let fb = new Date(date_b);
            if (fa > fb) {
              return -1;
            }
            if (fa < fb) {
              return 1;
            }
          }
          return 0;
        } catch (error) {
          return 0;
        }
      });
      return ordered;
    },
  },
  watch: {
    impactList(newValue) {
      this.updatedImpactList = newValue;
    },
    selectedProject(newValue) {
      if (!newValue.value) return;

      if (!newValue.role) {
        this.currentRole = 'owner';
        return;
      }
      this.currentRole = newValue.role;
      // change the role depending on the selected project
      this.selectedProject = newValue;
    },
    projectList(newValue) {
      this.projectList = newValue;
    },
    showArchivedCompanies(value) {
      if (value) {
        this.showArchivedProjects = value;
      }
    }
  }
};
</script>

<style scoped>
@import "../assets/styles/views/EditProject.css";
</style>
